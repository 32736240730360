import React from "react";
import FormattedMessage from "../components/helpers/FormattedMessage";

const en = {
  'app.account-error.instructions': 'Something when wrong with accessing your account. We\'ve been notified and will let you know when you can login.',
  'app.account-error.title': 'There has been an error.',
  'app.action-plan-account.change-email.instruction1': 'Enter new email. This will change your login.',
  'app.action-plan-account.change-email.page-title': 'Email',
  'app.action-plan-account.change-password.instruction1': 'Enter your current password.',
  'app.action-plan-account.change-password.instruction2': 'Create a new password. Your new password should contain: ',
  'app.action-plan-account.change-password.page-title': 'Password',
  'app.action-plan-account.change-password.reenter-new-password': 'Re-enter new password',
  'app.action-plan-account.change-password.step-one': 'Step One: ',
  'app.action-plan-account.change-password.step-three': 'Step Three: ',
  'app.action-plan-account.change-password.step-two': 'Step Two: ',
  'app.action-plan-account.change-phone.instruction1': 'Enter new cell phone number',
  'app.action-plan-account.change-phone.page-title': 'Cell phone number',
  'app.action-plan-account.page-title': 'My Account',
  'app.action-plan-budget.button.hide-details': 'Hide details',
  'app.action-plan-budget.button.save': 'Save',
  'app.action-plan-budget.button.saved-message': 'Saved!',
  'app.action-plan-budget.button.saved-message': 'Saved!',
  'app.action-plan-budget.button.show-details': 'Show details',
  'app.action-plan-budget.button.show-fewer': 'Show fewer sources',
  'app.action-plan-budget.button.show-more': 'Show more sources',
  'app.action-plan-budget.counselor.advice': 'By completing a budget, we can get a better understanding of the flow of your money coming in and out each month. Then we can work together to see if there\'s anything that can be put towards debt or saving for your goals.',
  'app.action-plan-budget.expense.education-child-care': 'Education and Child Care',
  'app.action-plan-budget.expense.entertainment': 'Entertainment',
  'app.action-plan-budget.expense.food': 'Food',
  'app.action-plan-budget.expense.home': 'Home',
  'app.action-plan-budget.expense.other': 'Other',
  'app.action-plan-budget.expense.personal-care': 'Personal Care',
  'app.action-plan-budget.expense.title': 'Monthly Expenses',
  'app.action-plan-budget.expense.transportation': 'Transportation',
  'app.action-plan-budget.expense.utilities': 'Utilities',
  'app.action-plan-budget.income.child-support': 'Child Support',
  'app.action-plan-budget.income.disability': 'SSI / SSD',
  'app.action-plan-budget.income.help-fam-friends': 'Help from Family or Friends',
  'app.action-plan-budget.income.my-business': 'Income from My Business',
  'app.action-plan-budget.income.my-partner-pay': 'My Partner\'s Pay',
  'app.action-plan-budget.income.my-partner-side-gig': 'My Partner\'s Side Gig',
  'app.action-plan-budget.income.my-pay': 'My Pay',
  'app.action-plan-budget.income.my-side-gig': 'My Side Gig',
  'app.action-plan-budget.income.other': 'Other',
  'app.action-plan-budget.income.over-budget': 'OVER BUDGET: ',
  'app.action-plan-budget.income.pa-tanf': 'Public Assistance / TANF',
  'app.action-plan-budget.income.remaining': 'REMAINING: ',
  'app.action-plan-budget.income.rental-income': 'Rental Income',
  'app.action-plan-budget.income.snap': 'SNAP / Food Stamps',
  'app.action-plan-budget.income.ssr': 'Social Security / Retirement',
  'app.action-plan-budget.income.title': 'Monthly Income',
  'app.action-plan-budget.income.total': 'TOTAL (AVG)',
  'app.action-plan-budget.income.unemployment': 'Unemployment',
  'app.action-plan-budget.income.wic': 'WIC',
  'app.action-plan-budget.page-title': 'My Budget',
  'app.action-plan-budget.warning.dont-save': 'Don\'t save',
  'app.action-plan-budget.warning.notice-changes': 'I noticed you made some changes to your budget without saving!',
  'app.action-plan-budget.warning.save': 'Save',
  'app.action-plan-budget.warning.wait-a-second': 'Wait a second!',
  'app.action-plan-dashboard.button.complete-profile': 'Edit your profile',
  'app.action-plan-dashboard.button.see-dashboard': 'See my dashboard',
  'app.action-plan-dashboard.button.view-sessions': 'View Sessions',
  'app.action-plan-dashboard.dcl.description': 'In your first session with your financial coach, you will review your pre-screener responses and credit report, and then discuss your debt consolidation loan options. From there, you will work together on a plan of action.',
  'app.action-plan-dashboard.dcl.header-description': 'Congratulations on taking important steps toward your debt consolidation journey! Your financial coach is looking forward to working with you.',
  'app.action-plan-dashboard.dcl.header-title': 'Your journey to debt consolidation starts here',
  'app.action-plan-dashboard.dcl.title': 'Debt Consolidation: Next Steps',
  'app.action-plan-dashboard.link-accounts.button.linked': 'Link more accounts',
  'app.action-plan-dashboard.link-accounts.description-1.linked':'Thank you for linking your accounts! If you\'d like to link additional accounts, visit the ',
  'app.action-plan-dashboard.link-accounts.description-1.unlinked':' to see if there are any opportunities to',
  'app.action-plan-dashboard.link-accounts.description-2.linked': ' tab.',
  'app.action-plan-dashboard.link-accounts.description-2.unlinked':' on financial products and services found in connection with your account(s).',
  'app.action-plan-dashboard.link-accounts.description-3.unlinked': 'How does it work?',
  'app.action-plan-dashboard.link-accounts.description-4.unlinked':'After you link, we’ll flag the debt products and fees that tend to be overly expensive or unnecessary. We can help you find alternatives and put money back in your pocket.',
  'app.action-plan-dashboard.link-accounts.description-5.unlinked': 'Ready to link your accounts?',
  'app.action-plan-dashboard.link-accounts.description-6.unlinked':'Please link your primary bank account first, followed by others you’d like us to scan.',
  'app.action-plan-dashboard.link-accounts.description-bold.unlinked':' save money',
  'app.action-plan-dashboard.link-accounts.description-link.linked': 'Link Accounts',
  'app.action-plan-dashboard.link-accounts.description-link.unlinked': 'Link your bank and credit accounts',
  'app.action-plan-dashboard.link-accounts.financial-snapshot.button': 'Go to Link Accounts',
  'app.action-plan-dashboard.link-accounts.financial-snapshot.description-1':'Head to ',
  'app.action-plan-dashboard.link-accounts.financial-snapshot.description-2': ' to download your new financial snapshot now. You can also view the accounts you have linked, link more, or remove some.',
  'app.action-plan-dashboard.link-accounts.financial-snapshot.link': 'Link Accounts',
  'app.action-plan-dashboard.link-accounts.financial-snapshot.title': 'Your financial snapshot is ready for download!',
  'app.action-plan-dashboard.link-accounts.linked': 'Your Linked Accounts',
  'app.action-plan-dashboard.link-accounts.unlinked-1':'Updated feature!',
  'app.action-plan-dashboard.link-accounts.unlinked-2':'Link Accounts',
  'app.action-plan-dashboard.my-sessions':'Upcoming Sessions',
  'app.action-plan-dashboard.status': 'Status:',
  'app.action-plan-dashboard.status-incomplete': 'Incomplete',
  'app.action-plan-dashboard.status-review-your-responses': 'Complete',
  'app.action-plan-dashboard.status-complete': 'Complete',
  'app.action-plan-dashboard.status-incomplete': 'Incomplete',
  'app.action-plan-dashboard.status-review-your-responses': 'Complete',
  'app.action-plan-debt-summary.balance': 'BALANCE',
  'app.action-plan-debt-summary.category': 'CATEGORY',
  'app.action-plan-debt-summary.counselor.no-score': 'Not having a score does not mean that you have bad credit. You may be new to the U.S., young, or just new to credit in general, and that’s okay. But without a credit history, it could be a challenge to get a credit card, a loan, and in some cases even an apartment or a phone contract. It can take some time to build your credit score, but with responsible and consistent credit use, you will be able to build your score. We can help you understand the best options for you to help you establish credit.',
  'app.action-plan-debt-summary.counselor.unfamiliar-advice': 'If something looks unfamiliar here, we can help investigate the account and provide clarity on how to address it.',
  'app.action-plan-debt-summary.detail.account-interest-rate' : 'Interest rate',
  'app.action-plan-debt-summary.detail.account-interest-rate-message' : 'Interest rates aren’t on credit reports. We can advise better how to pay down debt with it!',
  'app.action-plan-debt-summary.detail.account-interest-rate-message' : 'Interest rates aren’t on credit reports. We can advise better how to pay down debt with it!',
  'app.action-plan-debt-summary.detail.account-ownership-type' : 'Account ownership',
  'app.action-plan-debt-summary.detail.back-to-overview': 'Back to overview',
  'app.action-plan-debt-summary.detail.balance': 'Balance',
  'app.action-plan-debt-summary.detail.balance-exceeds-limit': 'Balance exceeds limit',
  'app.action-plan-debt-summary.detail.confirmed-account': 'Thanks for confirming this information !',
  'app.action-plan-debt-summary.detail.credit-limit': 'Credit limit',
  'app.action-plan-debt-summary.detail.flagged-account' : 'You’ve flagged this account because something was incorrect.',
  'app.action-plan-debt-summary.detail.information-correct': 'Does the information above look correct?',
  'app.action-plan-debt-summary.detail.information-correct-no': 'No',
  'app.action-plan-debt-summary.detail.information-correct-yes': 'Yes',
  'app.action-plan-debt-summary.detail.information-correct-no': 'No',
  'app.action-plan-debt-summary.detail.information-correct-yes': 'Yes',
  'app.action-plan-debt-summary.detail.last-activity-date': 'Last activity date',
  'app.action-plan-debt-summary.detail.link': 'Details',
  'app.action-plan-debt-summary.detail.min-payment': 'Min. payment',
  'app.action-plan-debt-summary.detail.open-date': 'Open date',
  'app.action-plan-debt-summary.detail.original-creditor': 'Original creditor',
  'app.action-plan-debt-summary.detail.remove-flag-link' : 'Remove flag',
  'app.action-plan-debt-summary.detail.status-of-account': 'Status of account',
  'app.action-plan-debt-summary.detail.type-of-account': 'Type of account',
  'app.action-plan-debt-summary.detail.undo-confirmation-link' : 'Undo confirmation',
  'app.action-plan-debt-summary.key': 'KEY',
  'app.action-plan-debt-summary.less.link':'Less',
  'app.action-plan-debt-summary.monthly-payment': 'MINIMUM PAYMENT',
  'app.action-plan-debt-summary.more.link':'More',
  'app.action-plan-debt-summary.no-data': 'We don\'t see any credit cards or loans on your report.',
  'app.action-plan-debt-summary.page-title': 'Debt Summary',
  'app.action-plan-debt-summary.permission-button': 'Give us permission',
  'app.action-plan-debt-summary.permission-subtitle': 'You’re missing out!',
  'app.action-plan-debt-summary.permission-text': 'We can’t pull your debt summary or show your FICO® Score without getting permission to pull your credit report.',
  'app.action-plan-debt-summary.permission-text-transunion': 'We can’t pull your debt summary without getting permission to pull your credit report.',
  'app.action-plan-debt-summary.permission-text-2': 'Please note that it may take up to 48 hours after giving us permission for your information to appear.',
  'app.action-plan-debt-summary.permission-text-2': 'Please note that it may take up to 48 hours after giving us permission for your information to appear.',
  'app.action-plan-debt-summary.permission-text-transunion': 'We can’t pull your debt summary without getting permission to pull your credit report.',
  'app.action-plan-debt-summary.subtitle': 'Here’s your total debt according to your credit report pulled on ',
  'app.action-plan-debt-summary.waiting-subtitle': 'Give us time!',
  'app.action-plan-debt-summary.waiting-text': 'If you gave us permission to access your credit it will take 1 or 2 minutes in most cases for your information to appear here.',
  'app.action-plan-feedback.comments': 'Comments',
  'app.action-plan-feedback.comments.required': 'If you would like to submit feedback, please enter your comments above.',
  'app.action-plan-feedback.comments.required.notice': 'Comments',
  'app.action-plan-feedback.feedback-prompt-1': 'We welcome your feedback! Did something give you trouble? Are you looking for a new feature? Let us know below! These comments will be forwarded over to our tech and client experience team.',
  'app.action-plan-feedback.feedback-prompt-2': 'Have an important message for your coach? Text ',
  'app.action-plan-feedback.feedback-prompt-3':' to get in touch with them more quickly!',
  'app.action-plan-feedback.page-title': 'Feedback',
  'app.action-plan-feedback.thank-you': 'Thank you for submitting your feedback.',
  'app.action-plan-fico-score.credit-education-link': 'Click for more credit education.',
  'app.action-plan-fico-score.desc-insufficient-text': 'Unfortunately, we do not have a FICO® Score to display at this time because your file does not contain the minimum information necessary.  Your credit report must contain information from at least one account that has been open for a 6 month period',
  'app.action-plan-fico-score.desc-insufficient-title': 'The Profile report does not contain sufficient credit information',
  'app.action-plan-fico-score.desc-kim-title': 'Key Factors affecting your FICO® Score',
  'app.action-plan-fico-score.disclaimer-title': 'FICO® Score Disclaimer: ',
  'app.action-plan-fico-score.disclaimer1': 'The FICO® Score we provide may be different from other credit scores. FICO® Scores and educational content intended only for your personal educational review purposes. Lenders may use various inputs like a FICO® Score, other credit scores and other information in credit decisions. The credit file used to create your FICO® Score is continually updated, and your FICO® Score above may not reflect the most current data on your credit file. To request a copy of your credit report please visit: ',
  'app.action-plan-fico-score.disclaimer2': 'http://www.annualcreditreport.com',
  'app.action-plan-fico-score.disclaimer3': 'The person and/or organization providing this report and Fair Isaac are not "credit repair" organizations as defined under federal or state law, including the Credit Repair Organizations Act.',
  'app.action-plan-fico-score.disclaimer4': 'FICO® and “The score lenders use” are registered trademarks of Fair Isaac Corporation in the United States and other countries.',
  'app.action-plan-fico-score.disclaimer5': 'Fair Isaac does not guarantee the accuracy of information input by the coach.',
  'app.action-plan-fico-score.keep-in-mind': 'Keep in mind: ',
  'app.action-plan-fico-score.page-title': 'FICO® Score',
  'app.action-plan-fico-score.transunion1': 'Your FICO® Score 4 based on TransUnion® Data is the same score that TrustPlus pulled on ',
  'app.action-plan-fico-score.transunion2': ' and is provided for your own non-commercial personal review and benefit.',
  'app.action-plan-link-money-accounts.financial-snapshot.button': 'Download your financial snapshot',
  'app.action-plan-link-money-accounts.financial-snapshot.title': 'Your Financial Snapshot is Ready for Download!',
  'app.action-plan-link-money-accounts.header': 'Link Accounts',
  'app.action-plan-link-money-accounts.link-more.description': 'If you\'d like to link more accounts, click the button below!',
  'app.action-plan-link-money-accounts.link-more.title': 'Link More Accounts',
  'app.action-plan-link-money-accounts.linked-accounts.description-1': 'Below are your linked accounts.',
  'app.action-plan-link-money-accounts.linked-accounts.description-2': 'Do you want to ',
  'app.action-plan-link-money-accounts.linked-accounts.description.link': 'link more accounts',
  'app.action-plan-link-money-accounts.linked-accounts.financial-snapshot.description-1': 'The financial snapshot flags any debt products and fees found in your linked accounts that tend to be overly expensive or unnecessary. We can help you find alternatives and put money back in your pocket. If no negative products and fees were found, your snapshot will indicate that as well.',
  'app.action-plan-link-money-accounts.linked-accounts.financial-snapshot.description-2': 'Check the Downloads folder on your computer or device, as well as your email inbox.',
  'app.action-plan-link-money-accounts.linked-accounts.financial-snapshot.header': 'Complete!',
  'app.action-plan-link-money-accounts.title': 'Your Linked Accounts',
  'app.action-plan-link-money-confirmation.button': 'Link another account',
  'app.action-plan-link-money-confirmation.description': 'Your account has been successfully verified and linked.',
  'app.action-plan-link-money-confirmation.header': 'Congratulations!',
  'app.action-plan-link-money-modal.description-1': ' to see if there are opportunities to ',
  'app.action-plan-link-money-modal.description-2': 'save money',
  'app.action-plan-link-money-modal.description-3': ' on negative financial products and services connected to your account(s). ',
  'app.action-plan-link-money-modal.financial-snapshot.description-1': 'Head to ',
  'app.action-plan-link-money-modal.financial-snapshot.description-2': ' to download your new financial snapshot.',
  'app.action-plan-link-money-modal.financial-snapshot.link': 'Link Accounts',
  'app.action-plan-link-money-modal.financial-snapshot.title': 'Your financial snapshot is ready for download!',
  'app.action-plan-link-money-modal.link-1': 'Link your bank and credit accounts',
  'app.action-plan-link-money-modal.link-2': 'Learn more',
  'app.action-plan-link-money-modal.title': 'Updated feature!',
  'app.action-plan-link-money.button.cancel': 'Remind me later',
  'app.action-plan-link-money.button.link-accounts': 'Link your accounts',
  'app.action-plan-link-money.confirmation': 'Thank you for linking your accounts.',
  'app.action-plan-link-money-confirmation.header': 'Congratulations!',
  'app.action-plan-link-money-confirmation.description': 'Your account has been successfully verified and linked.',
  'app.action-plan-link-money-confirmation.button': 'Link another account',
  'app.action-plan-link-money.instructions': 'Link your accounts for a faster, in-depth analysis of your debts',

  'app.action-plan-link-money.title': 'Link Accounts',
  'app.action-plan-link-money.welcome': 'Take the next step on your journey',
  'app.action-plan-link-money.welcome.description-1': 'The more data we have, the better we can support you on your journey to get out of debt and achieve your dreams. ',
  'app.action-plan-link-money.welcome.description-link': 'Link your accounts',
  'app.action-plan-link-money.welcome.description-2': ' now!',

  'app.action-plan-link-money.welcome.how-does-it-work.title': 'How does it work?',
  'app.action-plan-link-money.welcome.how-does-it-work-1': 'Linking your accounts gives us ',
  'app.action-plan-link-money.welcome.how-does-it-work-1a': 'confidential, secure access ',
  'app.action-plan-link-money.welcome.how-does-it-work-1b': 'to scan for debt products and fees that tend to be overly expensive or unnecessary.',

  'app.action-plan-link-money.welcome.how-does-it-work-2': 'This will help our coaches find better alternatives for you, so that you can put',
  'app.action-plan-link-money.welcome.how-does-it-work-2a': ' money back in your pocket.',

  'app.action-plan-link-money.welcome.how-does-it-work-3': 'After the scan is complete, we will',
  'app.action-plan-link-money.welcome.how-does-it-work-3a':' email ',
  'app.action-plan-link-money.welcome.how-does-it-work-3b':'you a link to download your',
  'app.action-plan-link-money.welcome.how-does-it-work-3c':' Financial Snapshot',
  'app.action-plan-link-money.welcome.how-does-it-work-3d':', which identifies what debt products and fees are found.',

  'app.action-plan-link-money.welcome.how-does-it-work-4': 'If nothing negative is found, we will let you know that in the Financial Snapshot as well.',

  'app.action-plan-link-money.welcome.ready-link-accounts-title': 'Ready to link your accounts?',
  'app.action-plan-link-money.welcome.ready-link-accounts-1': 'Please link your ',
  'app.action-plan-link-money.welcome.ready-link-accounts-1a': 'primary bank',
  'app.action-plan-link-money.welcome.ready-link-accounts-1b': ' account first, followed by others you’d like us to scan.',

  'app.action-plan-link-money.welcome.privacy-1': 'Your privacy and data security are important to us.',
  'app.action-plan-link-money.welcome.privacy-2': 'All of your information is safely encrypted.',

  'app.action-plan-link-money.welcome.description-link': 'Link your accounts',


  'app.action-plan-link-money.welcome.privacy-1': 'Your privacy and data security are important to us.',
  'app.action-plan-link-money.welcome.privacy-2': 'All of your information is safely encrypted.',
  'app.action-plan-messages.page-title': 'Messages',
  'app.action-plan-messaging.compose': 'Compose New Message',
  'app.action-plan-messaging.new': 'New',
  'app.action-plan-nav.and': ' and ',
  'app.action-plan-nav.budget': 'Budget',
  'app.action-plan-nav.button.schedule': 'Schedule session',
  'app.action-plan-nav.debt-summary': 'Debt Summary',
  'app.action-plan-nav.espanol': 'Español',
  'app.action-plan-nav.feedback' : 'Feedback',
  'app.action-plan-nav.fico-score': 'FICO® Score',
  'app.action-plan-nav.link-money': 'Link Accounts',
  'app.action-plan-nav.link-money-category-chart': 'Link Money Category Chart',
  'app.action-plan-nav.link-money-accounts': 'Link Money Accounts',
  'app.action-plan-nav.link-money-transactions': 'Link Money Transactions',
  'app.action-plan-nav.link-money-accounts': 'Link Money Accounts',
  'app.action-plan-nav.link-money-category-chart': 'Link Money Category Chart',
  'app.action-plan-nav.link-money-transactions': 'Link Money Transactions',
  'app.action-plan-nav.messages': 'Messages',
  'app.action-plan-nav.my-account': 'My Account',
  'app.action-plan-nav.privacy-policy': 'Privacy Policy',
  'app.action-plan-nav.sessions': 'Sessions',
  'app.action-plan-nav.sign-out': 'Sign Out',
  'app.action-plan-nav.take-action-today': 'Take Action Today',
  'app.action-plan-nav.terms-of-service': 'Terms of Service',
  'app.action-plan-nav.welcome-name': 'Welcome, ',
  'app.action-plan-sessions.no-previous': 'No previous sessions',
  'app.action-plan-sessions.no-upcoming': 'No upcoming sessions',
  'app.action-plan-sessions.on': ' on ',
  'app.action-plan-sessions.page-title': 'My Sessions',
  'app.action-plan-sessions.previous': 'Previous',
  'app.action-plan-sessions.upcoming': 'Upcoming',
  'app.action-plan-take-action-today.achieved': 'Achieved',
  'app.action-plan-take-action-today.achieved-date' : 'Achieved on: ',
  'app.action-plan-take-action-today.achieved-date' : 'Achieved on: ',
  'app.action-plan-take-action-today.cancel' : 'Cancel',
  'app.action-plan-take-action-today.create' : 'Create an action step',
  'app.action-plan-take-action-today.create-submit' : 'Finish Creating',
  'app.action-plan-take-action-today.create-title': 'New action step',
  'app.action-plan-take-action-today.create-submit' : 'Finish Creating',
  'app.action-plan-take-action-today.create-title': 'New action step',
  'app.action-plan-take-action-today.due-date' : 'Due Date: ',
  'app.action-plan-take-action-today.how-to-achieve': 'How to achieve:',
  'app.action-plan-take-action-today.input.due-date' : 'Due date',
  'app.action-plan-take-action-today.input.goal-comment' : 'Your action step',
  'app.action-plan-take-action-today.input.goal-comment.placeholder': 'One short sentence for yourself on what you should do. This will be sent to you as a reminder.',
  'app.action-plan-take-action-today.input.goal-comment.placeholder': 'One short sentence for yourself on what you should do. This will be sent to you as a reminder.',
  'app.action-plan-take-action-today.input.how-accomplish' : 'How to achieve',
  'app.action-plan-take-action-today.input.how-accomplish.placeholder': 'Add some more details about how you plan to achieve this action. What smaller steps will you take?',
  'app.action-plan-take-action-today.input.how-accomplish.placeholder': 'Add some more details about how you plan to achieve this action. What smaller steps will you take?',
  'app.action-plan-take-action-today.input.motivation' : 'Motivation (optional)',
  'app.action-plan-take-action-today.input.motivation.placeholder': 'What will this action help you to accomplish? How will this impact your long-term goal?',
  'app.action-plan-take-action-today.input.motivation.placeholder': 'What will this action help you to accomplish? How will this impact your long-term goal?',
  'app.action-plan-take-action-today.mark-as-achieved' : 'Mark as Achieved',
  'app.action-plan-take-action-today.no-completed-items': 'There are no completed items.',
  'app.action-plan-take-action-today.no-current-to-do-items': 'There are no current To Do items.',
  'app.action-plan-take-action-today.page-title': 'Take Action Today',
  'app.action-plan-take-action-today.to-do': 'To Do',
  'app.alt-company-login.login-text': "Login via ",
  'app.alt-company-login.text-1': 'Normally access TrustPlus via your employer’s website? You can log in here:',
  'app.appointment.description': 'Our counselors are available at these times. Each session will take 30 minutes. All times are local based on your location.',
  'app.appointment.description2': 'If you don\'t have 30 minutes to spare, try ',
  'app.appointment.description.link': 'sending a message',
  'app.appointment.description3': ' directly to a coach.',
  'app.appointment.pick-a-time-with': 'Pick a time with ',
  'app.appointment.submit.button': 'Submit',
  'app.appointment.timezone': 'What time zone is this client’s appointment in?',
  'app.appointment.timezone-note': '(Note: please take standard and daylight savings time into effect when choosing from the following options):',
  'app.appointment.timezone.answer.none': 'Please select from the following',
  'app.appointment.timezone.answer.est': 'Eastern Standard Time, EST',
  'app.appointment.timezone.answer.cst': 'Central Standard Time, CST',
  'app.appointment.timezone.answer.mst': 'Mountain Standard Time, MST',
  'app.appointment.timezone.answer.pst': 'Pacific Standard Time, PST',
  'app.appointment.timezone.answer.ast': 'Alaska Standard Time, AST',
  'app.appointment.timezone.answer.hst': 'Hawaii-Aleutian Standard Time, HST',
  'app.appointment.timezone.answer.edt': 'Eastern Daylight Time, EDT',
  'app.appointment.timezone.answer.cdt': 'Central Daylight Time, CDT',
  'app.appointment.timezone.answer.mdt': 'Mountain Daylight Time, MDT',
  'app.appointment.timezone.answer.pdt': 'Pacific Daylight Time, PDT',
  'app.appointment.timezone.answer.adt': 'Alaska Daylight Time, ADT',
  'app.appointment.timezone.answer.hdt': 'Hawaii-Aleutian Standard Time, HST',
  'app.appointment.timezone-submit': 'Submit',
  'app.appointment.timezone-note': '(Note: please take standard and daylight savings time into effect when choosing from the following options):',
  'app.appointment.timezone-submit': 'Submit',
  'app.appointment.title': 'Pick your coach',
  'app.b-flow.appointment-select-coach.instruction.2': 'Select a coach:',
  'app.b-flow.appointment-select-coach.instruction.3': 'Select a coach on the left to read more about them.',
  'app.b-flow.appointment-select-coach.text.skip': 'I do not wish to speak with a coach at this time.',
  'app.b-flow.appointment-select-coach.title': 'Let\'s start by selecting a financial coach for your 30-minute one-on-one session.',
  'app.b-flow.credit-report-form-page.button.continue': 'Continue',
  'app.b-flow.credit-report-form-page.dcl.text': 'This will be a “soft pull,” meaning this will not impact your credit score. Pulling your credit report now will help you and your financial coach make the most out of your first session. It will also grant you access to the TrustPlus portal to keep track of your progress towards debt consolidation.',
  'app.b-flow.credit-report-form-page.dcl.title': 'Last step—pull your credit report (it\'s a soft pull)',
  'app.b-flow.credit-report-form-page.text': 'By pulling your credit report now, you and your coach will have a clear picture of your finances during the first session, maximizing your success when tackling debt. This will be a “soft pull,” meaning this will not impact your credit score.',
  'app.b-flow.credit-report-form-page.text-2': 'All fields are required.',
  'app.b-flow.credit-report-form-page.text-2': 'All fields are required.',
  'app.b-flow.credit-report-form-page.title': 'Make the most of your first session by pulling your credit report (it\'s a soft pull)',
  'app.b-flow.email-form.button.continue': 'Continue',
  'app.b-flow.email-form.placeholder.email': 'email address',
  'app.b-flow.email-form.title': 'Get started by entering your email address',
  'app.b-flow.forgot-password-confirmation.continue.button': 'Continue',
  // 'app.b-flow.forgot-password-confirmation.instruction': 'No problem! We will send a message with a temporary code to your email address.',
  'app.b-flow.forgot-password-confirmation.instruction': 'Thank you for confirming your email. You may now proceed to create a new password.',
  // 'app.b-flow.forgot-password-confirmation.instruction': 'No problem! We will send a message with a temporary code to your email address.',
  'app.b-flow.forgot-password-confirmation.instruction': 'Thank you for confirming your email. You may now proceed to create a new password.',
  'app.b-flow.forgot-password-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  'app.b-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.b-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.b-flow.forgot-password-form.send.button': 'Send link',
  'app.b-flow.forgot-password-form.title': 'Forgot password?',
  'app.b-flow.full-name-form.agreement.description': 'I agree to the {termsLink} and {privacyLink}.',
  'app.b-flow.full-name-form.agreement.privacy-link': 'Privacy Policy',
  'app.b-flow.full-name-form.agreement.terms-link': 'Terms of Service',
  'app.b-flow.full-name-form.continue.button': 'Continue',
  'app.b-flow.full-name-form.instruction': 'Enter your first and last name.',
  'app.b-flow.full-name-form.not-new': 'I\'m not new. Try a different email.',
  'app.b-flow.full-name-form.placeholder.first-name': 'first name',
  'app.b-flow.full-name-form.placeholder.last-name': 'last name',
  'app.b-flow.full-name-form.title': 'You seem new here!',
  'app.b-flow.login-password-form.alt-link': 'Forgot password?',
  'app.b-flow.login-password-form.instruction': 'Enter your password.',
  'app.b-flow.login-password-form.placeholder.password': 'password',
  'app.b-flow.login-password-form.title': 'Welcome back!',
  'app.b-flow.new-password-form.show-password': 'Show password',
  'app.b-flow.new-password-form.continue.button': 'Continue',
  'app.b-flow.new-password-form.instruction': 'Password must: ',
  'app.b-flow.new-password-form.placeholder.password': 'password',
  'app.b-flow.new-password-form.placeholder.re-enter-password': 're-enter password',
  'app.b-flow.new-password-form.rules-1': 'Be at least 8 characters',
  'app.b-flow.new-password-form.rules-2': 'Contain upper and lower case letters',
  'app.b-flow.new-password-form.rules-3': 'Cannot contain special characters',
  'app.b-flow.new-password-form.rules-4': 'Contain at least one number',
  'app.b-flow.new-password-form.rules-5': 'Passwords must match',
  'app.b-flow.new-password-form.title': 'Now make a password.',
  'app.b-flow.pick-a-time.change-coach.button': '< Back to all coaches',
  'app.b-flow.pick-a-time.instruction': 'Set up a 30-minute session with your coach. If your selected financial coach is unavailable, you can go back and pick a different one.',
  'app.b-flow.pick-a-time.title': 'Pick a time',
  'app.b-flow.pick-a-time.with': 'Pick a time with ',
  'app.b-flow.pull-credit-report.text.skip': 'Remind me later',
  'app.b-flow.session-scheduled.additional-questions': 'Continue',
  'app.b-flow.session-scheduled.description-1a': 'On the day of the appointment, ',
  'app.b-flow.session-scheduled.description-1b': ' will text you a video link or call you from a dedicated number with a 929 area code. ',
  'app.b-flow.session-scheduled.description-2': 'You can make the most out of your session by taking a few extra steps to ensure a comprehensive and personalized experience. By providing access to your credit report and filling out our assessment form you enable us to gain valuable insights into your financial profile.',
  'app.b-flow.session-scheduled.reschedule-link': 'Reschedule this session',
  'app.b-flow.session-scheduled.upcoming-session': 'Your upcoming session',
  'app.b-flow.temp-code-form.continue.button': 'Continue',
  'app.b-flow.temp-code-form.instruction-1': 'Please check your text messages for a 6-digit code, and enter it below.',
  'app.b-flow.temp-code-form.instruction-2': 'I want to use a different mobile number.',
  'app.b-flow.temp-code-form.instruction-3': 'I didn\'t receive a code.',
  'app.b-flow.temp-code-form.instruction-resent.text-1': 'We’ve just sent a new 6-digit code to your mobile number. It may take a minute to arrive.',
  'app.b-flow.temp-code-form.instruction-resent.text-2': 'When ready, please enter the code below.',
  'app.b-flow.temp-code-form.modal.link': 'Get new code.',
  'app.b-flow.temp-code-form.modal.title': 'The code you entered is invalid or expired. ',
  'app.b-flow.temp-code-form.title': 'Thanks, ',
  'app.b-flow.temp-code-form.unsuccessful': 'The verification was unsuccessful. Please check your temp code and try again.',
  'app.client-stories.description-1': 'Has TrustPlus helped you in a time of need? Did you have trouble filing your taxes this year or receiving your full refund? Has anything outside of your control stood in the way of you achieving your financial goals? Or do you just want to say “Thank You” to your Financial Coach?',
  'app.client-stories.description-2': 'We’d love to hear from you whether it’s a few sentences or just a few words! Responses may be used in promotional materials alongside the name you provide below, and one of our team members may reach out to you to hear more details about your experience.',
  'app.client-stories.disclosure': 'By entering my full legal name below as an electronic signature, I confirm I am at least 18 years of age and am competent to contract in my own name. I have reviewed this form before signing below and I fully understand the contents, meaning, and impact.',
  'app.client-stories.form-heading': 'Your Story',
  'app.client-stories.heading': 'Share Your Story',
  'app.client-stories.input.comment': 'Comment',
  'app.client-stories.input.phone': 'Cell number (in case we need to reach out)',
  'app.client-stories.input.preferred-name': 'Display name (this can be a pseudonym)',
  'app.client-stories.input.signature': 'Your full legal name/Electronic signature',
  'app.client-stories.submit.button': 'Send story',
  'app.client-stories.thanks-body-1': 'Thank you for sharing your story! We are proud of our clients and the work that we do with you, and we appreciate your willingness to share!',
  'app.client-stories.thanks-body-2': 'A team member from our fundraising team may reach out to learn more in the coming weeks. But no further action is required at this time.',
  'app.client-stories.thanks-heading': 'Thank you',
  'app.common.button.goback': 'Go back',
  'app.common.button.gohome': 'Return to Home',
  'app.common.cancel.button': 'Cancel',
  'app.common.change-coach.button': 'Change coach',
  'app.common.continue.button': 'Continue',
  'app.common.edit.button': 'Edit',
  'app.common.email.support': 'support@mytrustplus.org',
  'app.common.email.support-text': 'Email Support',
  'app.common.email.support-text': 'Email Support',
  'app.common.emergency-covid-email.support': 'covidsupport@mytrustplus.org',
  'app.common.got-it.button': 'Got it!',
  'app.common.keep-going.button': 'Keep Going',
  'app.common.link.external.google': 'support.google.com/meet/answer/9852160',
  'app.common.link.external.privacy': 'neighborhoodtrust.org/privacy-policy/',
  'app.common.link.external.skype': 'www.skype.com/en/legal',
  'app.common.link.external.terms': 'neighborhoodtrust.org/terms-of-service/',
  'app.common.link.external.zoom': 'zoom.us/docs/en-us/privacy-and-security.html',
  'app.common.next.button': 'Next',
  'app.common.phone.tollfree': '800-927-8772',
  'app.common.punctuation.space': ' ',
  'app.common.save.button': 'Save',
  'app.cp-flow.create-pass-form.agreement.description': 'I agree to the {termsLink} and {privacyLink}.',
  'app.cp-flow.create-pass-form.agreement.privacy-link': 'Privacy Policy',
  'app.cp-flow.create-pass-form.agreement.terms-link': 'Terms of Service',
  'app.cp-flow.create-password-form.title': 'Finally, make a password.',
  'app.cp-flow.forgot-password-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  'app.cp-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.cp-flow.forgot-password-form.instruction2': 'Please check your email for the link to reset your password.',
  'app.cp-flow.forgot-password-form.send.button': 'Send link',
  'app.cp-flow.forgot-password-form.title': 'Forgot password?',
  'app.cp-flow.login-form.intro': 'Log in by entering the email address you used when scheduling a demo.',
  'app.cp-flow.login-form.title': 'Welcome!',
  'app.cp-flow.login-password-form.alt-link': 'Forgot your password?',
  'app.cp-flow.password-form.instruction': 'Enter your password.',
  'app.cp-flow.password-form.title': 'Welcome back!',
  'app.cp-flow.temp-code-form.instructions': 'Please verify your email address by entering the one-time code we just sent to it.',
  'app.cp-flow.temp-code-form.instructions-2': '(It may take a minute+ to arrive.)',
  'app.cp-flow.temp-code-form.instructions-2': '(It may take a minute+ to arrive.)',
  'app.cp-flow.temp-code-form.title': 'Welcome!',
  'app.create-pass.instruction1': 'Password must: ',
  'app.create-pass.rules1': 'Must have a minimum of 8 characters',
  'app.create-pass.rules2': 'Contain upper and lower case letters',
  'app.create-pass.rules3': 'Contain at least one number',
  'app.create-pass.rules4': 'Cannot contain special characters',
  'app.create-pass.rules5': 'Passwords must match',
  'app.create-pass.title': 'Create a password',
  'app.create-password.password': 'Password',
  'app.create-password.reenter-password': 'Re-enter password',
  'app.credit-report-form.authorization': 'By clicking the blue button below, you authorize TrustPlus to pull your credit report for the purposes stated above. To further provide you with support and monitor your progress, you authorize TrustPlus to obtain your credit report from Transunion® or any of the three credit reporting agencies for the duration of your engagement in TrustPlus services and for up to 26 months thereafter.',
  'app.credit-report-form.date-of-birth.placeholder': 'MM/DD/YYYY',
  'app.credit-report-form.date-of-birth.title': 'Date of birth',
  'app.credit-report-form.go-to-credit-report': 'Pull my credit report',
  'app.credit-report-form.input.city': 'City',
  'app.credit-report-form.input.phone-number': 'Cell phone number',
  'app.credit-report-form.input.ssn': 'Social Security Number or ITIN',
  'app.credit-report-form.input.ssn.text': 'Your Social Security Number or ITIN will only be used to pull your credit report, nothing else, we promise.',
  'app.credit-report-form.input.ssn.link': 'Why are we asking for this information?',
  'app.credit-report-form.input.ssn.link': 'Why are we asking for this information?',
  'app.credit-report-form.input.ssn.text': 'Your Social Security Number or ITIN will only be used to pull your credit report, nothing else, we promise.',
  'app.credit-report-form.input.state': 'State',
  'app.credit-report-form.input.street': 'Home street address',
  'app.credit-report-form.input.zip-code': 'Zip code',
  'app.credit-report-form.ssn-modal.body.1': 'We only use your SSN/ITIN, date of birth, and home address to uniquely identify you. We\'re unable to get your credit report without these.',
  'app.credit-report-form.ssn-modal.body.2': 'We encrypt your SSN/ITIN, storing it in the following format: ',
  'app.credit-report-form.ssn-modal.body.3': 'To see more details about our security policy, visit {link}',
  'app.credit-report-form.ssn-modal.title': 'We take security seriously.',
  'app.credit-report-form.text': 'We need a few pieces of information in order to pull your credit report and get your debt summary. All fields are required.',
  'app.credit-report-form.title': 'Pull your credit report.',
  'app.customer-portal-common.back-link': "\< Back",
  'app.customer-portal-contracts.about-you-text-2': 'Please note that some fields will be filled in already, based on the questionnaire answers you submitted before getting a demo.',
  'app.customer-portal-contracts.about-you.heading': 'Generate your contract',
  'app.customer-portal-contracts.about-you.text-1': 'Begin generating your TrustPlus contract by completing the fields below.',
  'app.customer-portal-contracts.btn-1': 'Generate Your Contract',
  'app.customer-portal-contracts.btn-3': 'Go to My Accounts',
  'app.customer-portal-contracts.detail.preview.contract.button':'Preview Contract',
  'app.customer-portal-contracts.form.about.intro-text-1': 'Please complete the following sections, representing the primary contact and signor.',
  'app.customer-portal-contracts.form.about.select':'Please select',
  'app.customer-portal-contracts.form.common.intro-text-2': ' If anything looks incorrect, please ',
  'app.customer-portal-contracts.form.common.intro-text-3': 'contact Milly',
  'app.customer-portal-contracts.form.common.intro-text-4': ' to make changes.',
  'app.customer-portal-contracts.form.details.contract-length': 'Length of Contract',
  'app.customer-portal-contracts.form.details.contract-length-unit': ' months',
  'app.customer-portal-contracts.form.details.contract-length-unit': ' months',
  'app.customer-portal-contracts.form.details.contract-start-date': 'Contract Start Date',
  'app.customer-portal-contracts.form.details.intro-text-1': 'Your contract includes two webinars. Do you want to add any additional webinars?',
  'app.customer-portal-contracts.form.details.table-header-left': 'Your selection',
  'app.customer-portal-contracts.form.details.table-header-right-1': 'Cost: $',
  'app.customer-portal-contracts.form.details.table-header-right-2': '/each',
  'app.customer-portal-contracts.form.employees.intro-text-1': 'This is our understanding of the number of employees who will participate.',
  'app.customer-portal-contracts.form.employees.table-header-left': 'Number of Employees',
  'app.customer-portal-contracts.form.employees.table-header-right': 'Cost per Employee',
  'app.customer-portal-contracts.form.footer-text-1': 'If anything looks incorrect, please ',
  'app.customer-portal-contracts.form.footer-text-2': 'contact Milly',
  'app.customer-portal-contracts.form.footer-text-3': ' to make changes.',
  'app.customer-portal-contracts.form.footer.contract-details': 'Our base implementation fee of $3,000 generally includes co-branded marketing, quarterly reporting, and our standard financial coaching services. This fee is subject to change if the employer requires service customizations (i.e. in-person presence or customized reporting).',
  'app.customer-portal-contracts.form.step-1.intro-text': 'Please complete the following sections',
  'app.customer-portal-contracts.form.step-1.title': 'About the Primary Contact and Signor for the Organization',
  'app.customer-portal-contracts.form.step-5.intro-text': 'By typing your name below, you are signing the contract and agreeing to the Terms of Service.',
  'app.customer-portal-contracts.heading-1': 'Start the seamless contract process below.',
  'app.customer-portal-contracts.heading-2': 'You’ve signed your contract look for an email regarding your invoice soon.',
  'app.customer-portal-contracts.heading-3': 'You’re up to date!',
  'app.customer-portal-contracts.progress-bar-details.text-1': 'This progress indicator lets you know where you are in the contract process.',
  'app.customer-portal-contracts.progress-bar-details.text-2': 'Not done',
  'app.customer-portal-contracts.progress-bar-details.text-3': 'Complete',
  'app.customer-portal-contracts.progress-bar-details.text-4': 'Each number represents the following:',
  'app.customer-portal-contracts.progress-bar-details.text-5': 'Select number of webinars',
  'app.customer-portal-contracts.progress-bar-details.text-6': 'Review contract offering/pricing',
  'app.customer-portal-contracts.progress-bar-details.text-7': 'Contract duration',
  'app.customer-portal-contracts.progress-bar-details.text-8': 'Contract start date',
  'app.customer-portal-contracts.progress-bar-details.title': 'Progress Indicator Details',
  'app.customer-portal-contracts.progress-bar-details.title-2': 'Color Key',
  'app.customer-portal-contracts.progress-bar-details.title-2': 'Color Key',
  'app.customer-portal-contracts.progress-bar-step-title.title-1': 'About',
  'app.customer-portal-contracts.progress-bar-step-title.title-2': 'Your Employees',
  'app.customer-portal-contracts.progress-bar-step-title.title-3a': 'Contract ',
  'app.customer-portal-contracts.progress-bar-step-title.title-3b': 'Details',
  'app.customer-portal-contracts.progress-bar-step-title.title-4a': 'Preview ',
  'app.customer-portal-contracts.progress-bar-step-title.title-4b': 'Contract',
  'app.customer-portal-contracts.progress-bar-step-title.title-5a': 'Sign ',
  'app.customer-portal-contracts.progress-bar-step-title.title-5b': 'Contract',
  'app.customer-portal-contracts.progress-bar-step-title.title-6a': 'Submit ',
  'app.customer-portal-contracts.progress-bar-step-title.title-6b': 'Contract',
  'app.customer-portal-contracts.progress-indicator-heading': 'This progress indicator lets you know where you are in the contract process:',
  'app.customer-portal-contracts.submit-subtitle-1': 'Onboarding',
  'app.customer-portal-contracts.submit-subtitle-2': 'Invoicing',
  'app.customer-portal-contracts.submit-title-1': 'What happens next?',
  'app.customer-portal-contracts.submit.text-1': 'Navigate to Onboarding to begin uploading your documents. Starting this process now will allow your team to receive Financial Coaching as soon as the contract begins, if they would like to.',
  'app.customer-portal-contracts.submit.text-2': 'Our Finance team will reach out to you soon with an invoice. Please note that if your start date is not for a few months, you will receive the invoice closer to that date.',
  'app.customer-portal-contracts.text-1a': 'TrustPlus makes it easy to help your employees ease the stress of the everyday money worries that hamper wellness and productivity.',
  'app.customer-portal-contracts.text-1b': 'Ready to invest in the financial health of your workers?',
  'app.customer-portal-contracts.text-2a': 'Milly has shared your contract with the Finance team so that they can process your invoice. Keep an eye out for an email from bill.com in about __________ days/weeks. That email will have a link to pay your invoice directly. If you have any questions in the meantime, please feel free to contact Milly.',
  'app.customer-portal-contracts.text-3a': 'You have already signed your contract and onboarded with TrustPlus—there’s nothing you need to do on this screen!',
  'app.customer-portal-contracts.text-3b': 'If you’d like to view your contract history, please navigate to My Accounts.',
  'app.customer-portal-footer.text-1':'If you have questions at any time, you can ',
  'app.customer-portal-footer.text-2':'contact',
  'app.customer-portal-home.back-to-progress': 'Back to your progress',
  'app.customer-portal-home.contracts.jump-to-bottom': 'Jump to Bottom',
  'app.customer-portal-home.contracts.jump-to-top': 'Jump Back to Top',
  'app.customer-portal-home.contracts.miss-something': 'Miss something?',
  'app.customer-portal-home.contracts.miss-something-2': 'and use the progress bar to navigate to previous screens.',
  'app.customer-portal-home.contracts.miss-something-2': 'and use the progress bar to navigate to previous screens.',
  'app.customer-portal-home.contracts.sign-contracts': 'By clicking “Submit Contract” above, you are signing the contract and agreeing to the Terms of Service.',
  'app.customer-portal-home.intro':'Congratulations on taking meaningful steps to maximize the financial health of your workers.',
  'app.customer-portal-home.onboarding-heading': 'What happens after TrustPlus sign-up is complete?',
  'app.customer-portal-home.onboarding-heading-2': 'It’s time to onboard your team!',
  'app.customer-portal-home.onboarding-heading-2': 'It’s time to onboard your team!',
  'app.customer-portal-home.onboarding-sub-heading': 'Employee Onboarding',
  'app.customer-portal-home.onboarding-sub-heading-2a': 'Meet Your Relationship Manager',
  'app.customer-portal-home.onboarding-sub-heading-2a': 'Meet Your Relationship Manager',
  'app.customer-portal-home.onboarding-text-1': 'Shortly after signing up, the onboarding process can begin for your employees. At this stage, we will put you in touch with one of our Relationship Managers (RM), who will be your primary TrustPlus contact. The RM will schedule a call with you so you can create a launch plan together!',
  'app.customer-portal-home.onboarding-text-2': 'Meet your new Relationship Manager, Brendon Kinney! Brendon will be your primary TrustPlus contact. Keep an eye out for a message from him soon, where he will schedule a call to get you started!',
  'app.customer-portal-home.progress-done-heading': "You’re signed up for TrustPlus",
  'app.customer-portal-home.progress-heading': 'Your Sign-Up Progress',
  'app.customer-portal-home.progress-sign-up-complete-header': 'Sign-up Is Complete',
  'app.customer-portal-home.progress-sign-up-complete-text': 'Your Relationship Manager will get in touch with you shortly to create a launch plan.',
  'app.customer-portal-home.progress-sign-up-complete-thanks': 'Thank you!',
  'app.customer-portal-home.progress-step-1': 'Step 1:',
  'app.customer-portal-home.progress-step-1-instructions': 'Create an account (Done)',
  'app.customer-portal-home.progress-step-1-instructions': 'Create an account (Done)',
  'app.customer-portal-home.progress-step-2': 'Step 2:',
  'app.customer-portal-home.progress-step-2-instructions': 'Generate and sign contract',
  'app.customer-portal-home.progress-step-2-instructions': 'Generate and sign contract',
  'app.customer-portal-home.progress-step-3': 'Step 3:',
  'app.customer-portal-home.progress-step-3-instructions': 'Onboard',
  'app.customer-portal-home.progress-step-3-instructions': 'Onboard',
  'app.customer-portal-home.progress-step-4-instructions': 'Generate and sign contract (Done)',
  'app.customer-portal-home.progress-step-5-instructions': 'Onboard (Done) ',
  'app.customer-portal-home.progress-sub-heading': 'Just a Few More Steps!' ,
  'app.customer-portal-home.progress-view-steps': 'View all steps',
  'app.customer-portal-home.welcome-name': 'Welcome, ',
  'app.customer-portal-messaging.btn-1': 'Send Message',
  'app.customer-portal-messaging.sent': 'Sent',
  'app.customer-portal-messaging.sent.sent-exclaim': 'Sent!',
  'app.customer-portal-messaging.sent.sent-exclaim': 'Sent!',
  'app.customer-portal-nav.account':'My Account',
  'app.customer-portal-nav.contracts': 'Contracts',
  'app.customer-portal-nav.dashboard': 'Home',
  'app.customer-portal-nav.faq':'FAQ',
  'app.customer-portal-nav.reports':'Reports',
  'app.customer-portal.contracts-download': 'Download Contract',
  'app.customer-portal.contracts-header': 'Contracts',
  'app.customer-portal.contracts-submit-contract': 'Submit Contract',
  'app.customer-portal.contracts.download-text': 'Check the Downloads folder on your computer or device.',
  'app.customer-portal.contracts.go-to-onboarding': 'Go to Onboarding',
  'app.customer-portal.contracts.submit-thank-you': 'Thank you for signing your TrustPlus contract!',
  'app.customer-portal.dashboard-header': 'Dashboard',
  'app.customer-portal.my-account-header':'My Account',
  'app.customer-portal.my-account-heading-1':'Use this page to manage your settings and security',
  'app.customer-portal.my-account.email': 'Email Address',
  'app.customer-portal.my-account.email-confirmation': 'Your email has been updated.',
  'app.customer-portal.my-account.email-text-1': 'Below is the email address we have on file.',
  'app.customer-portal.my-account.email-text-2': 'To change it, please enter an updated email address diectly in the box.',
  'app.customer-portal.my-account.email-text-3': 'Your email address has been updated.',
  'app.customer-portal.my-account.email-confirmation': 'Your email has been updated.',
  'app.customer-portal.my-account.email-text-1': 'Below is the email address we have on file.',
  'app.customer-portal.my-account.email-text-2': 'To change it, please enter an updated email address diectly in the box.',
  'app.customer-portal.my-account.first-name': 'First Name',
  'app.customer-portal.my-account.last-name': 'Last Name',
  'app.customer-portal.my-account.name': 'Name',
  'app.customer-portal.my-account.name-confirmation': 'Your name has been updated.',
  'app.customer-portal.my-account.name-text-1': 'Below is the contact information we have on file.',
  'app.customer-portal.my-account.name-text-2': 'To change it, please enter your updated info directly in the boxes.',
  'app.customer-portal.my-account.name-text-3': 'Your name has been updated.',
  'app.customer-portal.my-account.name-confirmation': 'Your name has been updated.',
  'app.customer-portal.my-account.name-text-1': 'Below is the contact information we have on file.',
  'app.customer-portal.my-account.name-text-2': 'To change it, please enter your updated info directly in the boxes.',
  'app.customer-portal.my-account.phone': 'Phone Number',
  'app.customer-portal.my-account.phone-text-1': 'Below is the phone number we have on file.',
  'app.customer-portal.my-account.phone-text-2': 'To change it, please enter an updated number directly in the box.',
  'app.customer-portal.my-account.phone-text-3': 'Your phone number has been updated.',
  'app.customer-portal.my-account.phone-text-1': 'Below is the phone number we have on file.',
  'app.customer-portal.my-account.phone-text-2': 'To change it, please enter an updated number directly in the box.',
  'app.customer-portal.my-account.phone-text-3': 'Your phone number has been updated.',
  'app.customer-portal.my-account.security': 'Security',
  'app.customer-portal.my-account.security-text-1': 'Use this area to update your password.',
  'app.customer-portal.my-account.security-text-2': 'Confirm your current password in the first box.',
  'app.customer-portal.my-account.security-text-3': 'Add a new password in the second box.',
  'app.customer-portal.my-account.security-text-4': 'Passwords must:',
  'app.customer-portal.my-account.security-text-5': 'Be at least 8 characters',
  'app.customer-portal.my-account.security-text-6': 'Contain upper and lower case letters',
  'app.customer-portal.my-account.security-text-7': 'Have at least one special character (!, @, #, etc.)',
  'app.customer-portal.my-account.security-text-8': 'Your password has been updated.',
  'app.customer-portal.my-account.security-current-password': 'Current password',
  'app.customer-portal.my-account.security-new-password': 'New password',
  'app.customer-portal.my-account.security-confirm-password': 'Confirm password',
  'app.customer-portal.my-account.security-confirm-password': 'Confirm password',
  'app.customer-portal.my-account.security-current-password': 'Current password',
  'app.customer-portal.my-account.security-new-password': 'New password',
  'app.customer-portal.my-account.security-text-1': 'Use this area to update your password.',
  'app.customer-portal.my-account.security-text-2': 'Confirm your current password in the first box.',
  'app.customer-portal.my-account.security-text-3': 'Add a new password in the second box.',
  'app.customer-portal.my-account.security-text-4': 'Passwords must:',
  'app.customer-portal.my-account.security-text-5': 'Be at least 8 characters',
  'app.customer-portal.my-account.security-text-6': 'Contain upper and lower case letters',
  'app.customer-portal.my-account.security-text-7': 'Have at least one special character (!, @, #, etc.)',
  'app.customer-portal.my-account.security-text-8': 'Your password has been updated.',
  'app.customer-portal.my-account.settings': 'Settings',
  'app.customer-portal.my-account.text-1': 'Update your name and contact information',
  'app.customer-portal.my-account.text-2': 'Change your password',
  'app.customers.schedule-a-demo.button.continue': 'Continue',
  'app.customers.schedule-a-demo.input-company-name': 'Employer name',
  'app.customers.schedule-a-demo.input-email': 'Email',
  'app.customers.schedule-a-demo.input-name': 'Name',
  'app.customers.schedule-a-demo.input-size-of-workforce': 'Number of employees',
  'app.customers.schedule-a-demo.input-title': 'Title',
  'app.customers.welcome': 'Please provide the following information to schedule your demo:',
  'app.dcl-landing-page.aria-label.dcl-survey-start': 'The debt consolidation loan pre-screener has three questions that will focus on employment, income, and credit score',
  'app.dcl-landing-page.aria-label.hero': 'Happy Baltimore, Maryland residents who consolidated their credit card debt via TrustPlus vetted debt cosolidation loans',
  'app.dcl-landing-page.aria-label.testimonial-1': 'TrustPlus client Yakaira feeling hopeful after her financial coach helped her reach her financial goals',
  'app.dcl-landing-page.aria-label.testimonial-2': 'TrustPlus client Edward looks relaxed as he explains how his financial coach helped lower his credit card debt and save money',
  'app.dcl-landing-page.aria-label.testimonial-3': 'TrustPlus client Annette details how her financial coach helped reduce her monthly debt payments, saving thousands in interest',
  'app.dcl-landing-page.aria-label.testimonial-4': 'TrustPlus client James confidently shares how his financial coach helped him reduce debt and make progress toward his goals',
  'app.dcl-landing-page.aria-label.who-we-wre-photo': 'Dedicated TrustPlus financial coach Elise sharing her expertise during a remote coaching session with a client',
  'app.dcl-landing-page.benefits.subtitle-1': 'How you can benefit from a debt consolidation loan',
  'app.dcl-landing-page.benefits.subtitle-2': 'Take our quick pre-screener to get started!',
  'app.dcl-landing-page.benefits.text-1': 'Debt consolidation loans allow you to combine multiple debts into a single loan with one monthly payment at a lower interest rate. TrustPlus\' expert financial coaches rigorously search for the best debt consolidation loans. We do not offer the loans directly, nor do we receive compensation for our recommendations. Our commitment is to you—the client.',
  'app.dcl-landing-page.benefits.text-5': 'The pre-screener WILL NOT impact your credit score.',
  'app.dcl-landing-page.benefits.title': 'Benefits of Debt Consolidation Loans',
  'app.dcl-landing-page.benefits.title-1': 'Why A DEBT CONSOLIDATION IS RIGHT FOR YOU',
  'app.dcl-landing-page.benefits.title-1': 'Why A DEBT CONSOLIDATION IS RIGHT FOR YOU',
  'app.dcl-landing-page.contact.title': 'Contact Us',
  'app.dcl-landing-page.faq.answer-5.email': 'support@mytrustplus.org',
  'app.dcl-landing-page.faq.answer-5.text-1': 'You can email us at ',
  'app.dcl-landing-page.faq.answer-5.text-2': '. We look forward to serving you!',
  'app.dcl-landing-page.faq.title': 'FAQs',
  'app.dcl-landing-page.how-it-works.subtitle-1': 'Save money on interest',
  'app.dcl-landing-page.how-it-works.subtitle-2': 'One bill, less stress',
  'app.dcl-landing-page.how-it-works.subtitle-3': 'Access to financial coaching',
  'app.dcl-landing-page.how-it-works.subtitle-4': 'Vetted financial products',
  'app.dcl-landing-page.how-it-works.text-1': 'Consolidating high-interest credit card debt to a lower interest loan can help you save money and become debt-free sooner.',
  'app.dcl-landing-page.how-it-works.text-2': 'Simplify your debt management by combining multiple payments into one!',
  'app.dcl-landing-page.how-it-works.text-3': 'Our coaches offer personalized guidance and support tailored to your unique needs.',
  'app.dcl-landing-page.how-it-works.text-4': 'We’ve done the heavy lifting for you, and we don’t make a dime for our recommendations. It’s all about getting you the best product!',
  'app.dcl-landing-page.how-it-works.title': 'How It Works',
  'app.dcl-landing-page.introduction.first-step.button': 'Take our 3-question pre-screener',
  'app.dcl-landing-page.introduction.intro-blurb': 'Our expert financial coaches find the best debt consolidation loans from vetted lending institutions, saving you time and money.',
  'app.dcl-landing-page.introduction.intro-blurb-2': 'Answer three simple questions',
  'app.dcl-landing-page.introduction.intro-blurb-3': ' to get started!',
  'app.dcl-landing-page.introduction.intro-blurb-2': 'Answer three simple questions',
  'app.dcl-landing-page.introduction.intro-blurb-3': ' to get started!',
  'app.dcl-landing-page.introduction.title-1': 'Simplify Your',
  'app.dcl-landing-page.introduction.title-2': 'Credit Card Debt',
  'app.dcl-landing-page.our-impact.text-1': 'Median Debt Reduction Per Client',
  'app.dcl-landing-page.our-impact.text-2': 'Total Aggregate Debt Reduced Across All Clients',
  'app.dcl-landing-page.our-impact.text-3': 'Total Savings Built',
  'app.dcl-landing-page.our-impact.title': 'Our Impact',
  'app.dcl-landing-page.survey.question1-header': 'Question 1 of 3: ',
  'app.dcl-landing-page.survey.question1': 'What is your employment status?',
  'app.dcl-landing-page.survey.question1-header': 'Question 1 of 3: ',
  'app.dcl-landing-page.survey.question2-header': 'Question 2 of 3:',
  'app.dcl-landing-page.survey.question2': 'What is your annual income?',
  'app.dcl-landing-page.survey.question2-header': 'Question 2 of 3:',
  'app.dcl-landing-page.survey.question3-header': 'Question 3 of 3:',
  'app.dcl-landing-page.survey.question3': 'Do you have a rough idea of your credit score?',
  'app.dcl-landing-page.survey.question3-header': 'Question 3 of 3:',
  'app.dcl-landing-page.tagline.take-prescreener.button': 'Take our 3-question pre-screener',
  'app.dcl-landing-page.testimonials.testimonial-1': '“Working with Tiffany lifted a big load off my back. Her support and consistency were helpful; I couldn\'t have accomplished everything on my own.”',
  'app.dcl-landing-page.testimonials.testimonial-2': '“I was maxing out my credit cards. The interest was killing me. My financial coach straightened me out and now I can set aside a small amount in savings each month.”',
  'app.dcl-landing-page.testimonials.testimonial-3': '“Thanks to Elise, I was able to access a loan that reduced my monthly debt payment by $146, helping me save over $4K in interest and get out of debt 8 months sooner!”',
  'app.dcl-landing-page.testimonials.testimonial-4': '“Thanks to TrustPlus, I’m making strides towards reducing my debts. After exploring various options, I found the best path for me. I feel much more confident that I will achieve my goals sooner than anticipated.”',
  'app.dcl-landing-page.testimonials.testimonial-author-1': '—Yakaira',
  'app.dcl-landing-page.testimonials.testimonial-author-2': '—Edward',
  'app.dcl-landing-page.testimonials.testimonial-author-3': '—Annette',
  'app.dcl-landing-page.testimonials.testimonial-author-4': '—James',
  'app.dcl-landing-page.testimonials.title': 'Testimonials',
  'app.dcl-landing-page.who-we-are.image-caption': 'Elise, TrustPlus Financial Coach',
  'app.dcl-landing-page.who-we-are.text-1': 'TrustPlus is a worker financial health solution that draws on 30 years of non-profit leadership and expertise in helping businesses and organizations maximize the financial health and productivity of workers like you.',
  'app.dcl-landing-page.who-we-are.text-2': 'Across the U.S., TrustPlus Financial Coaches work one-on-one with individuals to help them optimize debt, strengthen credit, and build savings. These ongoing relationships lead to lasting impact for clients.',
  'app.dcl-landing-page.who-we-are.text-3': 'Our commitment is solely to you. We vet financial institutions to find the best product to help our clients better manage their debts and achieve their financial goals. And we do this while not taking a single dime from these institutions for our recommendations.',
  'app.dcl-landing-page.who-we-are.title': 'Who We Are',
  'app.dcl-landing-page.youtube': 'https://www.youtube.com/embed/_KH38LUDfWM',
  'app.dcl-prescreener.placeholder': "Select from dropdown menu",
  'app.dcl-landing-page.faq.question-1': 'What is a debt consolidation loan?',
  'app.dcl-landing-page.faq.answer-1': 'A debt consolidation loan combines multiple debts into one single loan with a lower interest rate, helping you save money and pay off your debt faster. It’s a convenient way to streamline your debts and reduce financial stress!',
  'app.dcl-landing-page.faq.question-2': 'What is the difference between a debt consolidation loan and a debt management plan?',
  'app.dcl-landing-page.faq.answer-2': 'A debt consolidation loan combines multiple debts into one new loan with a lower interest rate, simplifying payments and saving you money on interest. A debt management plan restructures your existing debt without new borrowing. While it simplifies your payments, it may not necessarily be the most cost effective solution to pursue.',
  'app.dcl-landing-page.faq.question-3': 'How does debt consolidation work at TrustPlus?',
  'app.dcl-landing-page.faq.answer-3': 'We know how stressful it can be to find the best financial product. That’s why we’re doing the leg work for you! We recommend our clients only debt consolidation loans from vetted and trusted credit unions. And we don’t make a dime from these recommendations. Our commitment is to you - the client.',
  'app.dcl-landing-page.faq.question-4': 'What happens after I take the pre-screener?',
  'app.dcl-landing-page.faq.answer-4': 'Based on your answers, you will be prompted to schedule a session with a TrustPlus financial coach to explore your options.',
  'app.dcl-landing-page.faq.question-5': 'How can I contact TrustPlus?',
  'app.dcl-landing-page.faq.answer-5.text-1': 'You can email us at ',
  'app.dcl-landing-page.faq.answer-5.email': 'support@mytrustplus.org',
  'app.dcl-landing-page.faq.answer-5.text-2': '. We look forward to serving you!',
  'app.dcl-prescreener.employment-status.answer.full-time': "Employed full time",
  'app.dcl-prescreener.employment-status.answer.part-time': "Employed part time",
  'app.dcl-prescreener.employment-status.answer.self-employed': "Self employed",
  'app.dcl-prescreener.employment-status.answer.unemployed': "Unemployed",
  'app.dcl-prescreener.annual-income.answer.0-20': "$0 - $20,000",
  'app.dcl-prescreener.annual-income.answer.20-40': "$20,001 - $40,000",
  'app.dcl-prescreener.annual-income.answer.40-60': "$40,001 - $60,000",
  'app.dcl-prescreener.annual-income.answer.60+': "Over $60,000",
  'app.dcl-prescreener.credit-score.answer.yes-under-620': "Yes - it's 619 or Lower",
  'app.dcl-prescreener.credit-score.answer.yes-620-or-over': "Yes - it's 620 or Higher",
  'app.dcl-prescreener.credit-score.answer.no': "I don't know",
  'app.dcl-prescreener.placeholder': "Select from dropdown menu",
  'app.debt-report-collections.dispute-back-to-summary-btn': 'Back to summary',
  'app.debt-report-collections.dispute-next-step-btn': 'Next step',
  'app.debt-report-collections.dispute-previous-step-btn': 'Previous step',
  'app.debt-report-collections.dispute-step-1-subtitle': 'Step 1',
  'app.debt-report-collections.dispute-step-1-text-1': 'Sign up for an account on ',
  'app.debt-report-collections.dispute-step-1-text-2': 'ServiceTransunion.com',
  'app.debt-report-collections.dispute-step-1-text-3': '. You will need to input your name, address, and the last four digits of your social security number. In addition, you will need to make a username and password. A code will be sent to your email to confirm your account.',
  'app.debt-report-collections.dispute-step-1-text-4': 'Note: You may be requested to verify your identity by answering questions that only you should know about your accounts. If unscucessful, you may need to call 866-744-8221. Follow the prompts, a service rep will pick up the call and ask questions to verify your identity.',
  'app.debt-report-collections.dispute-step-1-title': 'Dispute Instructions',
  'app.debt-report-collections.dispute-step-2-subtitle': 'Step 2',
  'app.debt-report-collections.dispute-step-2-text-1': 'On your TransUnion dashboard, find the “Dispute” section and click “Get Started.” You will see an informational panel where you will click “Start Request”. You will need to agree to the term.',
  'app.debt-report-collections.dispute-step-3-subtitle': 'Step 3',
  'app.debt-report-collections.dispute-step-3-text-1': 'Locate the incorrect items you previously identified:',
  'app.debt-report-collections.dispute-step-3-text-2': 'You will need to go through each one and click “Dispute”. Select “It is not mine” or “It’s inaccurate” to see more specific reasons. Select the one that best fits each account, and upload any supporting documentation if available (for instance, a notice from the lender that shows a balance was paid off). ',
  'app.debt-report-collections.dispute-step-3-text-3': 'Repeat this process for each of the items above.',
  'app.debt-report-collections.dispute-step-3-text-4': 'Note: supporting documents are NOT required.',
  'app.debt-report-collections.dispute-step-4-subtitle': 'Step 4',
  'app.debt-report-collections.dispute-step-4-text-1': 'Once you have submitted your dispute(s), you will be redirected to the dashboard where you can see any active items. You will also receive an email from Transunion confirming your dispute. They will also email you once an account has been resolved. It will likely take a couple of weeks for your status to be updated.',
  'app.debt-report-collections.help-dispute-back-to-collections': 'Back to all collections',
  'app.debt-report-collections.to-questionnaire': 'You\'ll be able to see information on your disputes again once you log in. But to help us deliver the best service to you, please answer some basic questions about yourself.',
  'app.debt-report-recommendations.collections': 'Collections',
  'app.debt-report-recommendations.collections-text-1a': 'You will be disputing the following ',
  'app.debt-report-recommendations.collections-text-1b': ' collections:',
  'app.debt-report-recommendations.collections-text-1':'We found ',
  'app.debt-report-recommendations.collections-text-2': ' collections on your Transunion report. A collection account represents a debt that an original creditor sold to another creditor--a collection agency--after the debt went unpaid for a certain amount of time. Collections decrease your credit score, but there are steps you can take to remediate them.',
  'app.debt-report-recommendations.collections-header': 'Collection Details',
  'app.debt-report-recommendations.collections-text-3': ' is looking to collect ',
  'app.debt-report-recommendations.collections-text-4': ' from a debt with ',
  'app.debt-report-recommendations.collections-text-6': 'I do not want to work on this right now.',
  'app.debt-report-recommendations.collections-text-7': 'You\'ve already looked at:',
  'app.debt-report-recommendations.collections-text-8': 'Waiting to dispute',
  'app.debt-report-recommendations.collections-btn-text': 'Work on this',
  'app.debt-report-recommendations.collections-btn-text': 'Work on this',
  'app.debt-report-recommendations.collections-header': 'Collection Details',
  'app.debt-report-recommendations.collections-text-1a': 'You will be disputing the following ',
  'app.debt-report-recommendations.collections-text-1b': ' collections:',
  'app.debt-report-recommendations.collections-text-1':'We found ',
  'app.debt-report-recommendations.collections-text-1a': 'You will be disputing the following ',
  'app.debt-report-recommendations.collections-text-1b': ' collections:',
  'app.debt-report-recommendations.collections-text-2': ' collections on your Transunion report. A collection account represents a debt that an original creditor sold to another creditor--a collection agency--after the debt went unpaid for a certain amount of time. Collections decrease your credit score, but there are steps you can take to remediate them.',
  'app.debt-report-recommendations.collections-text-3': ' is looking to collect ',
  'app.debt-report-recommendations.collections-text-4': ' from a debt with ',
  'app.debt-report-recommendations.collections-text-6': 'I do not want to work on this right now.',
  'app.debt-report-recommendations.collections-text-7': 'You\'ve already looked at:',
  'app.debt-report-recommendations.collections-text-8': 'Waiting to dispute',
  'app.debt-report-recommendations.debt-report-header': 'Credit report',
  'app.debt-report-recommendations.debt-report-text-1': 'Total Debt',
  'app.debt-report-recommendations.go-to-questionnaire': 'Go to questionnaire ',
  'app.debt-report-recommendations.let-us-know-about-you': 'Let us know about you',
  'app.debt-report-recommendations.new-top-priority': 'New Top Priority',
  'app.debt-report-recommendations.none-header': 'No Instant Recommendations',
  'app.debt-report-recommendations.none-text-1': 'We didn’t detect any immediate red flags for you to address, but our expert coaches will be reviewing your credit report carefully to help you to tackle any credit and debt issues you need in your upcoming session.',
  'app.debt-report-recommendations.none-text-2': 'You can explore the details yourself on the credit report tab.',
  'app.debt-report-recommendations.skip-these-questions': 'Skip these questions',
  'app.debt-report-recommendations.top-priority': 'Top Priority',
  'app.debt-report-reviewed-collections.btn-text-1': 'Dispute Online',
  'app.debt-report-reviewed-collections.dispute-offline': 'I want to dispute offline.',
  'app.debt-report-reviewed-collections.dispute-offline.title': 'Dispute Offline',
  'app.debt-report-reviewed-collections.dispute-offline.text-1': 'Your financial coach can help you dispute your collections accounts. They will provide you with a letter that you can send to TransUnion or any of the credit bureaus that details the accounts that you are disputing, the reason you are disputing them, as well as your rights as a consumer.',
  'app.debt-report-reviewed-collections.dispute-offline.text-1': 'Your financial coach can help you dispute your collections accounts. They will provide you with a letter that you can send to TransUnion or any of the credit bureaus that details the accounts that you are disputing, the reason you are disputing them, as well as your rights as a consumer.',
  'app.debt-report-reviewed-collections.dispute-offline.title': 'Dispute Offline',
  'app.debt-report-reviewed-collections.do-not-recognize-account.text-1': 'It is not uncommon for a credit report to contain inaccurate information. A 2021 Consumer Reports study shows that 34% of consumers found errors on their credit report.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.text-2': 'Fortunately, TransUnion provides an online tool for disputing items on your credit report. However, before we do that, let\'s make sure everything else looks good.',
  'app.debt-report-reviewed-collections.do-not-recognize-account.title': 'What to do when you don\'t recognize an account.',
  'app.debt-report-reviewed-collections.recognize-account.text-1': 'Ok, thanks for confirming that you recognize this debt.',
  'app.debt-report-reviewed-collections.recognize-account.text-2': 'If you’re ready to take action to address it, the first thing you will do with your coach is validate the debt to confirm that the collection agency reporting this debt is meeting their legal obligations.',
  'app.debt-report-reviewed-collections.recognize-account.text-3': 'Your coach will provide more details in your session and help you know how to prioritize this item among your other credit report accounts and goals.',
  'app.debt-report-reviewed-collections.recognize-account.title': 'What to do when you owe the balance on a collection.',
  'app.debt-report-reviewed-collections.text-1': 'You will be disputing the following ',
  'app.debt-report-reviewed-collections.text-2': ' transactions:',
  'app.debt-report-reviewed-collections.text-3': 'You have opted to speak with a coach about the following collection:',
  'app.debt-report-reviewed-collections.text-4': 'Session Scheduled',
  'app.debt-report-reviewed-collections.title': 'Collections',
  'app.debt-report-work-on-this.btn-1': 'I know this account, and I understand that I owe the balance',
  'app.debt-report-work-on-this.btn-2': 'I know this account, but I paid this debt and should not owe anything',
  'app.debt-report-work-on-this.btn-3': 'I do not recognize this account',
  'app.debt-report-work-on-this.header-text-1': 'What Does This Mean?',
  'app.debt-report-work-on-this.header-text-2': 'What Can I Do?',
  'app.debt-report-work-on-this.header-text-3': 'Do You Recognize This Account?',
  'app.debt-report-work-on-this.table-row-1': 'Original Creditor',
  'app.debt-report-work-on-this.table-row-2': 'Type of Account',
  'app.debt-report-work-on-this.table-row-2-text': 'Collection',
  'app.debt-report-work-on-this.table-row-2-text': 'Collection',
  'app.debt-report-work-on-this.table-row-3': 'Account Balance',
  'app.debt-report-work-on-this.table-row-4': 'Open Date',
  'app.debt-report-work-on-this.text-1': 'Here’s how this item appears on your credit report:',
  'app.debt-report-work-on-this.text-10': '. This includes the amount unpaid, plus fees and interest charges.',
  'app.debt-report-work-on-this.text-11': 'The “open date” with the collection agency is reported as ',
  'app.debt-report-work-on-this.text-12': '. This indicates the date where ',
  'app.debt-report-work-on-this.text-13': ' officially purchased the debt and began collecting the debt.',
  'app.debt-report-work-on-this.text-14': 'There are a few options! We’ll help guide you with some questions below.',
  'app.debt-report-work-on-this.text-15': 'First, ',
  'app.debt-report-work-on-this.text-10': '. This includes the amount unpaid, plus fees and interest charges.',
  'app.debt-report-work-on-this.text-11': 'The “open date” with the collection agency is reported as ',
  'app.debt-report-work-on-this.text-12': '. This indicates the date where ',
  'app.debt-report-work-on-this.text-13': ' officially purchased the debt and began collecting the debt.',
  'app.debt-report-work-on-this.text-14': 'There are a few options! We’ll help guide you with some questions below.',
  'app.debt-report-work-on-this.text-15': 'First, ',
  'app.debt-report-work-on-this.text-2': 'Credit reports can look confusing, but let’s break it down. ',
  'app.debt-report-work-on-this.text-3': ' is a collection agency that reported to Transunion(R) that they are the owners of one of your debts. It is very likely they have tried contacting you, either by mail or phone.',
  'app.debt-report-work-on-this.text-4': 'The original creditor is listed as ',
  'app.debt-report-work-on-this.text-5': ', which means that you originally owed them for the debt. After not receiving payment for a period of time, they sold the debt to ',
  'app.debt-report-work-on-this.text-6': '. It is unlikely that you can work with ',
  'app.debt-report-work-on-this.text-7': ' on the debt now that the collection agency has reported the debt.',
  'app.debt-report-work-on-this.text-8': 'The balance is reported as',
  'app.debt-report-work-on-this.text-9': ', which is likely more than you owed ',
  'app.debt-report.total-debt-subtitle': 'Here\'s your total debt according to your credit report.',
  'app.errors.birthdate.notOfAge': ' ',
  'app.errors.birthdate.notOfAge.notice': 'In order to use Neighborhood Trust\'s services, you must be 18 years of age or older. For more information, please review our {externalTermsOfSvc}. For questions, please email {mailToSupport} or call {tollfreePhone}.',
  'app.errors.birthdate.notOfAge.notice': 'In order to use Neighborhood Trust\'s services, you must be 18 years of age or older. For more information, please review our {externalTermsOfSvc}. For questions, please email {mailToSupport} or call {tollfreePhone}.',
  'app.errors.contact-exists': 'An account with this information already exists. Please {loginLink} to continue, or contact us for further assistance: {tollfreePhone} or {supportEmail}.',
  'app.errors.contact-exists.login' : 'login',
  'app.errors.contact-exists.login' : 'login',
  'app.errors.contact.create': 'An account with this information already exists. For further assistance call {tollfreePhone} or email {supportEmail}.',
  'app.errors.contact.create.link.external.self-scheduler.display': 'this link',
  'app.errors.contact.create.link.external.self-scheduler.display': 'this link',
  'app.errors.contact.update': 'It was not possible to obtain your credit report. Your coach will follow up on your appointment.',
  'app.errors.tempcode.required': 'Temporary code is required',
  'app.footer-ap.privacy':'Privacy Policy',
  'app.footer-ap.terms': 'Terms of Service',
  'app.footer-link-landing-page.all-rights-reserved': 'All Rights Reserved',
  'app.footer-link-landing-page.contact': 'Contact Us',
  'app.footer-link-landing-page.copyright': 'Copyright ©',
  'app.footer-link-landing-page.faq': 'FAQ',
  'app.footer-link-landing-page.follow': 'Follow Us',
  'app.footer-link-landing-page.neighborhood-trust': ' Neighborhood Trust',
  'app.footer-link-landing-page.pipe': ' | ',
  'app.footer-link-landing-page.privacy-policy': 'Privacy Policy',
  'app.footer-link-landing-page.tos': ' Terms of Service',
  'app.footer.calendly.intro': 'By scheduling a session, you confirm that you have read and agree to the TrustPlus ',
  'app.footer.privacy':'Privacy Policy',
  'app.footer.sponsored-by': 'Provided by',
  'app.footer.support': 'Need help? Email us at',
  'app.footer.terms': 'Terms of Service',
  'app.forgotpassword-confirmation.continue.button': 'Continue',
  'app.forgotpassword-confirmation.instruction': 'You may now proceed to create a new password.',
  'app.forgotpassword-confirmation.title': 'Thank you for confirming your email.',
  'app.forgotpassword-form.instruction': 'No problem! We will send a message to your email address with a link to reset your password.',
  // 'app.forgotpassword-form.instruction1': 'No problem! We will send a message with a temporary code to your phone number.',
  'app.forgotpassword-form.instruction1': 'Please check your email for the link to reset your password.',
  // 'app.forgotpassword-form.instruction1': 'No problem! We will send a message with a temporary code to your phone number.',
  'app.forgotpassword-form.instruction1': 'Please check your email for the link to reset your password.',
  // 'app.forgotpassword-form.instruction1': 'No problem! We will send a message with a temporary code to your phone number.',
  'app.forgotpassword-form.instruction1': 'Please check your email for the link to reset your password.',
  'app.forgotpassword-form.send.button': 'Send link',
  'app.forgotpassword-form.title': 'Forgot password?',
  'app.inactivity.continue': 'I\'m still here!',
  'app.inactivity.message.part1': 'You haven\'t done anything for a while. We\'re concerned with your security, so we\'ll log you out in ',
  'app.inactivity.message.part2': ' if you stay idle.',
  'app.inactivity.message.time': ' seconds',
  'app.introduction.locale-link': 'Español',
  'app.landing-page.coaches.hello-1': 'Hi there, I\'m ',
  'app.landing-page.coaches.hello-2': '!',
  'app.landing-page.coaches.title': 'Our coaches',
  'app.landing-page.company-logo.title': 'Provided By',
  'app.landing-page.how-it-works.text-1': 'You can chat with a coach via phone, video chat, or text.',
  'app.landing-page.how-it-works.text-2': 'Individualized financial plans will be created to meet your unique needs.',
  'app.landing-page.how-it-works.text-3': 'Our coaches provide one-on-one support and expert guidance—with no judgment.',
  'app.landing-page.how-it-works.text-4': 'All conversations are 100% confidential.',
  'app.landing-page.how-it-works.title': 'How It Works',
  'app.landing-page.introduction.debt': 'You have debt.',
  'app.landing-page.introduction.dreams-1': 'You have dreams.',
  'app.landing-page.introduction.first-step.button': 'Take the 1st step on your journey',
  'app.landing-page.introduction.hero-label-mobile': 'Elayna, Financial Coach',
  'app.landing-page.introduction.intro-blurb': 'Our experienced coaches have guided thousands of people like you to start their journey from the burden of debt to realizing their dreams.',
  'app.landing-page.introduction.intro-tagline': 'We are here for you.',
  'app.landing-page.introduction.log-on.button': 'Log In',
  'app.landing-page.introduction.sign-up.button-white': 'Sign Up',
  'app.landing-page.introduction.sign-up.button': 'Sign up!',
  'app.landing-page.introduction.sign-up.button-white': 'Sign Up',
  'app.landing-page.tagline.get-started.button': 'Get started!',
  'app.landing-page.tagline.text': 'It’s easy to get started with TrustPlus. Just click “Sign up” and select your 30 minute session.',
  'app.landing-page.tagline.title': 'Get started today!',
  'app.landing-page.testimonials.title': 'Testimonials',

  'app.learn-more.title': 'Frequently Asked Questions',
  'app.learn-more.question.1': 'What is TrustPlus?',
  'app.learn-more.answer.1a': 'TrustPlus is a financial wellness benefit that will help you build credit, reduce debt, and reach your financial goals. We are coaches that  provide one-on-one support and expert guidance- with no judgment, and are focused solely on improving your financial security. We do not sell financial products and never earn commissions.',
  'app.learn-more.answer.1b': 'TrustPlus is a service of Neighborhood Trust Financial Partners, a mission-driven, non-profit organization. For more than 20 years, we have helped more than 60,000 U.S. workers improve their credit scores, reduce their debt, and more. You can learn more about Neighborhood Trust and it’s services {neighborhoodtrusthome}.',
  'app.learn-more.question.2': 'How can TrustPlus help?',
  'app.learn-more.answer.2': 'TrustPlus can help address such issues as:',
  'app.learn-more.answer.2a': 'Building credit so that you can qualify for the best credit cards, loans, or mortgage',
  'app.learn-more.answer.2b': 'Reducing troublesome debt from payday loans and collections accounts to medical debt, student loan debt, and credit cards',
  'app.learn-more.answer.2c': 'Budgeting and building a safety net for emergencies',
  'app.learn-more.question.3': 'How does financial coaching work?',
  'app.learn-more.answer.3': 'Financial coaching provides a safe space for you to work towards your financial goals. During your coaching session, you will get to know your financial coach, share your story, provide some more details about your financial situation, and work together with your coach to build an action plan. You will also review your credit report and score. Your coach can also provide tools and support in reducing stress over your finances or tackling your burning financial questions. Sessions can be conducted via phone or video chat.',
  'app.learn-more.question.4': 'Will authorizing TrustPlus to pull my credit report hurt my credit score?',
  'app.learn-more.answer.4': 'No, we will do a “soft pull”, which has no effect on your credit report and score.',
  'app.learn-more.question.5': 'What happens after I meet with my financial coach?',
  'app.learn-more.answer.5': 'With TrustPlus, you get 12 months of access to the help you need—at no cost to you. As you work to reach your goals, your coach will continue to offer resources and guidance to support you, and you can contact them at any time. Connect as often or as little as needed via phone, video, chat or text.',
  'app.learn-more.question.6': 'Once I sign up for coaching, will I continue to have the same financial coach?',
  'app.learn-more.answer.6': 'As a client, you will have access to a team of financial coaches who are dedicated to helping improve your financial wellness and that of your colleagues. If the coach you choose is not available when you need them, our team of coaches will be there to support you. They are all trained in our unique methods to make sure you can success in reaching your goals. We will do our best to honor your preferences, but coach availability will vary.',
  'app.learn-more.question.7': 'What makes TrustPlus financial coaching different from other financial coaching and advising services?',
  'app.learn-more.question.7.1-heading': 'A Personalized Approach',
  'app.learn-more.answer.7.1': 'Unlike some financial coaching or coaching agencies, TrustPlus coaches will work with you individually to learn more about the goals you want to achieve and the unique circumstances of your financial situation and then partner with you in creating an action plan that is specific, relevant, and achievable for you. At other agencies, you might be taught a bunch of financial topics or be given a general laundry list of to-dos. And with other financial apps, your advice might come from algorithms and artificial intelligence. A combination of people and technology, TrustPlus reinforces our human guidance with relevant and safe financial products and tools to make our recommendations real, easier to adopt, and to help you make the most of every hard-earned paycheck.',
  'app.learn-more.question.7.2-heading': 'Trustworthy',
  'app.learn-more.answer.7.2': 'Unlike many financial advisors or financial companies, TrustPlus has your best interest at heart. Coaches will listen to you and provide guidance based on what is right for you and your family. We do not sell products or receive commissions. As a nonprofit, we’re unbiased and entirely motivated by your success and the changes that you will experience in your life after working with our coaches.',
  'app.learn-more.question.7.3-heading': 'Technology and Convenience',
  'app.learn-more.answer.7.3': 'TrustPlus coaches understand that you are busy and have limited time and energy to devote to working on your finances. Therefore, TrustPlus uses simple and user-friendly technology that will allow you to do everything you need at a time and place that is convenient to you. Individual coaching sessions are conducted via phone or video chat; and personalized financial action plans are delivered through an interactive online platform that you can access 24 hours a day. And to further assist you in staying on track among all of your responsibilities of daily life, TrustPlus will send you reminders based on the action items that you created with your financial coach.',
  'app.learn-more.question.8': 'How is TrustPlus different from credit repair or debt settlement services?',
  'app.learn-more.answer.8': 'Credit repair companies often charge upwards of $100/month to dispute errors on your credit report. This is something that you could do yourself for free, and our coaches arm you with the necessary tools to do so. Debt settlement companies promise to eliminate your debt burden, but it comes at a high price as your credit is often damaged during the process. They typically charge a fee of 25% of the settled debt. As with disputing errors on your credit report, this is something you can do yourself for free, and our coaches re here to provide the guidance and support throughout the process. ',
  'app.learn-more.question.9': 'Is my personal information secure? ',
  'app.learn-more.answer.9': 'We know security and privacy are important to you – and they are important to us, too. We make it a priority to provide strong security and give you confidence that your information is safe at all times. We use end-to-end Encryption technology such as Secure Sockets Layer (SSL) encryption, which helps to protect personal information in all areas of our website. Your information is GoDaddy SSL certified, one of the highest guarantees of privacy protection in the industry. And we don’t share your personal financial information with your employer or any third parties.',
  'app.learn-more.question.10': 'Can my family members and friends sign up for the service?',
  'app.learn-more.answer.10': 'TrustPlus’s financial coaching services are available as a benefit of your workplace or a membership in one of our partner organizations. Your significant other who participates in the financial decision-making in your home is welcome to be part of your TrustPlus coaching session over phone or video chat. For other friends and family, we are happy to help you find the right financial coaching services. Please ask your financial coach for more information.',
  'app.learn-more.question.11': 'Can I download the TrustPlus app to my phone?',
  'app.learn-more.answer.11': 'At this time we do not have a downloadable app. In order to make sure the service can be accessed wherever you are most comfortable, we have designed a web-based app that can be accessed with any smartphone, computer or tablet, and with most browsers.',
  'app.learn-more.question.12': 'What are the hours that coaches are available?',
  'app.learn-more.answer.12a': '9:00am - 9:00pm ET Monday through Thursday',
  'app.learn-more.answer.12b': '9:00am- 5:00pm ET Friday & Saturday',
  'app.learn-more.question.13': 'Who should I contact if I am having trouble accessing my account?',
  'app.learn-more.answer.13': 'If you are having trouble getting started or need more help, contact us at {supportEmail}.',
  'app.learn-more.question.14': 'How will TrustPlus access my credit report if my credit files are frozen?',
  'app.learn-more.answer.14a': 'If your credit files are frozen, TrustPlus may not be able to access your credit report. A credit freeze prevents credit reporting agencies from releasing your credit report to potential creditors, and coaching agencies that includes TrustPlus. ',
  'app.learn-more.answer.14b': 'However, if you want to review your credit report with TrustPlus while your credit files are frozen, you can temporarily lift the freeze with the credit reporting agencies. You will need to contact each credit bureau individually to request a temporary lift of the freeze, and they may require you to provide some information to verify your identity. Once the freeze is lifted, TrustPlus can access your credit report. ',
  'app.learn-more.question.15': 'What should I do if I am having trouble logging into my account?',
  'app.learn-more.answer.15a': 'If you are having touble logging into your account, here are some steps you can take to try and resolve the issue:',
  'app.learn-more.answer.15b': '1. Check your login credentials: Make sure you are entering the correct username and password. Check for typos, extra spaces, or capitalized letters that shouldn\'t be.',
  'app.learn-more.answer.15c': '2. Reset your password: If you can\'t remember your password, please utilize our "forgot password" option that will guide you through the process of resetting it.',
  'app.learn-more.answer.15d': '3. Clear your browser\'s cache and cookies: Sometimes login issues cna be caused by old or corrupted data storedin your browser\'s cache and cookies. Clearing them can often help resolve the issue.',
  'app.learn-more.answer.15e': '4. Try a different browser: If clearing your cache and cookies doesn\'t work, try using a different browser to see if the problem is specific to the one you were using. ',
  'app.learn-more.answer.15f': '5. Contact Customer support: If you\'ve tried all of the above steps and are still unable to log in, contact our customer support team for further assitance at support@mytrustplus.org. They may be able to identify and resolve the issue. ',
  'app.learn-more.question.16': 'How can I make sure that the call from my financial coach will pass the spam filters?',
  'app.learn-more.answer.16a': 'If you are expecting a call from your financial coach and are concerned that it might be flagged as spam or blocked by your phone\'s filters, there are a few things you can do to ensure that the call goes through:',
  'app.learn-more.answer.16b': '1. Add the coach\'s phone number to your contacts: By adding your coach\'s phone number to your contacts, you are telling your phone that this is a trusted number, and that calls from this number should be allowed through.',
  'app.learn-more.answer.16c': '2. Check your phone\'s blocked numbers list: Make sure that your coach\'s phone number is not on your phone\'s blocked numbers list. If it is, remove it from the list to allow the call to come through.',
  'app.learn-more.answer.16d': '3. Disable spam call blocking: Some phones have built-in spam call blocking features that can sometimes block legitimate calls. Check your phone\'s settings to see if this feature is turned on, and disable it temporarily if necessary.',
  'app.learn-more.answer.16e': '4. Contact your phone carrier: If you continue to have problems receiving calls from your financial coach, contact your phone carrier to see if they are blocking the calls. They may be able to add the number to a whitelist to allow it through.',
  'app.learn-more.answer.16f': '5. Provide an alternate number: If all else fails, consider providing an alternate number to your financial coach, such as a work number or a friend\'s phone, to ensure that you receive the call.',
  'app.learn-more.answer.16g': 'By taking these steps, you can help ensure that you receive the important call from your financial coach and avoid any potential issues with spam filters or call blocking.',
  'app.learn-more.question.17': 'What should I do if I can\'t find an available coach?',
  'app.learn-more.answer.17': 'Please consider using our "Ask a Coach" feature or emailing our support team at support@mytrustplus.org to speak with someone that can help. ',
  'app.learn-more.nytimes': 'The New York Times',
  'app.learn-more.vice': 'Vice',
  'app.learn-more.nprplanetmoney': 'NPR Planet Money',
  'app.learn-more.nycgov': 'NYC.gov',
  'app.learn-more.neighborhoodtrusthome':'here',

  'app.message.change-password.unsuccessful': 'Please check your password and try again.',
  // 'app.message.change-password.unsuccessful': 'Please check your password and try again.',
  'app.message.login.unsuccessful': 'Your login was unsuccessful. Please check your password and try again.',
  'app.message.tempcode.unsuccessful': 'Your login was unsuccessful. Please check your temp code and try again.',
  'app.newuser.alt-link': 'Try a different email address',
  'app.newuser.dcl.debtconsolidationloan.instruction1': 'We don\'t recognize your email, but you can get started today by ',
  'app.newuser.dcl.debtconsolidationloan.instruction2': 'taking our prescreener!',
  'app.newuser.instruction1': 'We don\'t recognize your email, but you can get started today by scheduling a session with a financial coach.',
  'app.newuser.title': 'New user',
  'app.no-employer-code.message.english': 'Thank you for accessing TrustPlus. The URL you entered is either missing a code for your employer, or incorrect. Please contact your employer\'s administrator or email {email} to receive the correct link.',
  'app.no-employer-code.message.spanish': 'Gracias por acceder a TrustPlus. La dirección que usted a ingresado no tiene el código para su empleador o es incorrecta. Por favor comuníquese con el administrador de su empleador o envíe un correo electrónico a {email} para recibir el enlace correcto.',
  'app.no-employer-code.title': 'TrustPlus',
  'app.notification.alt-link': 'I haven\'t received my code',
  'app.notification.instruction1': 'The final step is to create the password for your account. On the next page enter the temp code sent to your phone. Once you create your password, you\'ll be able to review details of your debt summary, see your credit score, manage your upcoming session, and create a budget to review with your coach.',
  'app.notification.instruction2': 'We\'ve already sent a temporary code to your phone number.',
  'app.notification.title': 'Create a login',
  'app.password.alt-link': 'Forgot password?',
  'app.password.input.password': 'Password',
  'app.password.instruction1': 'Enter your password to complete your login.',
  'app.password.title': 'Enter Password',
  'app.privacy-policy.all-about-cookies-link' : 'www.allaboutcookies.org',
  'app.privacy-policy.changes-to-privacy-policy.1': 'We may change this Privacy Policy from time to time. The most recent version of the Privacy Policy will always be posted on the Website, with the effective date posted at the top of the Privacy Policy. We may revise and update this Privacy Policy if our practices change, as technology changes, or as we add new services or change existing ones. If we make material changes to our Privacy Policy or how we handle your PII, or if we are going to use any PII in a manner that is materially different from that stated in our Privacy Policy at the time we collected such information, we will try to notify you of such changes reasonably in advance. By using our Website or Services after the effective date, you are deemed to consent to our then-current Privacy Policy.',
  'app.privacy-policy.changes-to-privacy-policy.sub-header': '11. Changes to this Privacy Policy',
  'app.privacy-policy.childrens-privacy.1': 'Our Services are not intended for or directed toward children under 13 years of age, and children should not use the Services for any purpose. If we learn that we have collected or received PII from a child under 13 years of age, we will delete that information.',
  'app.privacy-policy.childrens-privacy.2': 'If you are the parent or legal guardian of a child under 13 years of age who has registered on the Website or you believe has otherwise provided PII to Neighborhood Trust, please contact us at {mailToSupportAction} to have the account terminated and information deleted.',
  'app.privacy-policy.childrens-privacy.3': 'If you believe that we may have inadvertently collected any PII about a child aged 13 years or younger, please contact us at {mailToSupportAction} or as provided in the "How to contact us" section below.',
  'app.privacy-policy.childrens-privacy.sub-header': '6. Children\'s Privacy',
  'app.privacy-policy.how-to-contact.1': 'If you have questions, concerns or feedback regarding this Privacy Policy or our information practices, you can send an email to us at {mailToSupportAction} or by postal mail at:',
  'app.privacy-policy.how-to-contact.2': 'Attn: Legal Department',
  'app.privacy-policy.how-to-contact.3': 'The Neighborhood Trust Privacy Policy can also be found at {externalPrivacyPolicyLink}.',
  'app.privacy-policy.how-to-contact.sub-header': '12. How to Contact Us',
  'app.privacy-policy.how-we-disclose-your-information.1' : 'Disclosure to Third Parties: ',
  'app.privacy-policy.how-we-disclose-your-information.2': 'Other than the exceptions stated below, Neighborhood Trust pledges to not transfer your PII to any third party. We share PII and other information collected via our Services with other companies or individuals as follows:',
  'app.privacy-policy.how-we-disclose-your-information.3': 'When you are no longer our customer, we may continue to share your information as described in this Privacy Policy, including as necessary to comply with applicable laws or valid legal orders of a government authority of competent jurisdiction.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.1': 'When they help us operate our business, such as vendors and cloud providers who assist us with data storage, customer communication, outcome tracking, and analytics;',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.2': 'With third parties if we have a good faith belief that access, use or disclosure of such PII is reasonably necessary to (a) satisfy applicable law, regulation, legal process or an enforceable governmental request, (b) enforce the applicable Terms of Service or this Privacy Policy, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against harm to the rights, property or safety of Neighborhood Trust, its Users or the public, including as required or permitted by applicable laws or valid legal orders of a governmental authority of competent jurisdiction.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.3': 'With any of our subsidiaries, joint ventures, or other companies under common control with us, in which case we will require them to honor this Privacy Policy or terms which provide terms and conditions which are no less than stringent than those contained in this Privacy Policy. Additionally, in the event we go through a business transition such as a merger, acquisition by another company, or sale of all of a portion of our assets, your PII may be among the assets transferred.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.4': 'If requested, we may share your name with the organization that has connected you with the Services, but will not share your individual financial information.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.5': 'Your financial information may be aggregated with others\' financial information and shared with the organization that has connected you with this service for reporting and evaluation purposes only.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.6': 'PII that has been anonymized and aggregated may be shared with third parties for research, evaluation, and other internal business purposes.',
  'app.privacy-policy.how-we-disclose-your-information.bulletpoints.7': 'We do not sell your PII to third parties.',
  'app.privacy-policy.how-we-disclose-your-information.sub-header': '4. How We Disclose Your Information',
  'app.privacy-policy.how-we-use-your-information.1': 'In addition, we may use aggregate, statistical, and de-identified data, which does not specifically identify any individuals, for any purpose.',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.1': 'Deliver the Services that you have requested;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.2': 'Manage your account and provide you with customer support;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.3' : 'Perform research and analysis about your use of, or interest in, our Services or content available thereby, or products, services, or content offered by others;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.4' : 'Aggregate data such as the anonymous personal financial data you may provide via the Website. This aggregate information does not identify particular Users or otherwise allow anyone to recover sensitive information about individual Users.',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.5': 'Communicate with you by e-mail, postal mail, telephone, and/or mobile devices about products or services that may be of interest to you;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.6': 'Enforce our terms and conditions;',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.7': 'Manage our business; and',
  'app.privacy-policy.how-we-use-your-information.bulletpoints.8': 'Perform functions as otherwise described to you at the time of collection.',
  'app.privacy-policy.how-we-use-your-information.sub-header': '3. How We Use Your Information',
  'app.privacy-policy.how-we-use-your-information.we-may-use-info-to.subtitle': 'We may use information that we collect about you to:',
  'app.privacy-policy.information-we-collect.1': 'Throughout the course of using the Services, Neighborhood Trust may collect and store information you enter on our Website or information provided to us during a counseling session or in some other manner in connection with our provision of the Services. This includes Personally Identifiable Information ("PII"), including but not limited to: ',
  'app.privacy-policy.information-we-collect.information-you-provide.subtitle': 'Information You Provide',
  'app.privacy-policy.information-we-collect.passive-data-collection.subtitle' : 'Passive Data Collection',
  'app.privacy-policy.information-we-collect.sub-header': '2. Information We Collect',
  'app.privacy-policy.information-we-collect.third-party-advertisers.subtitle': 'Third Party Advertisers',
  'app.privacy-policy.last-revised': 'Last Revised: February 2020',
  'app.privacy-policy.notices-to-CA-residents.1': 'We will not share your personal information with third parties for their independent business purposes or direct marketing purposes without first giving you the ability to provide affirmative consent to such sharing. If you have provided such consent, then California\'s "Shine the Light" law permits you to request information regarding the disclosure of your Personal Information by us to a third party for the third party\'s direct marketing purposes. To make such a request, please sent an email to {mailToSupportAction}.',
  'app.privacy-policy.notices-to-CA-residents.sub-header': '8. Notices to California Residents',
  'app.privacy-policy.notices-to-VT-residents.1': 'We will not disclose information about your creditworthiness to our affiliates and will not disclose your personal information, financial information, credit report, or health information to nonaffiliated third parties to market to you, other than as permitted by Vermont law, unless you authorize us to make those disclosures. Additional information concerning our privacy policies can be found at {externalTosLink} or call {tollFreePhone}.',
  'app.privacy-policy.notices-to-VT-residents.2': 'You have the right to opt-out of the disclosure of any of the nonpublic personal information as stated above by notifying Neighborhood Trust in writing. In some instances, we may no longer be able to provide our services to you.',
  'app.privacy-policy.notices-to-VT-residents.3': 'Please see the contact information at the bottom of this document to opt-out.',
  'app.privacy-policy.notices-to-VT-residents.sub-header': '9. Notices to Vermont Residents',
  'app.privacy-policy.overview.intro.1': 'This Privacy Policy explains how Neighborhood Trust Financial Partners, LLC and its subsidiaries, partners and affiliates ("we", "us", or "Neighborhood Trust") collects, protects, uses and shares information about you when you use {ntfpSite} ("Website") and any of our services (collectively with the Website, the "Services"), and whether you access the Services directly or through a third party, which third parties may include your employer. This Privacy Policy is incorporated by reference in, and forms a part of, our Terms of Service, available at {tosLink} (the "Terms of Service"). In the event of any conflict between the provisions of this Privacy Policy and the Terms of Service, the applicable provisions of this Privacy Policy shall control.',
  'app.privacy-policy.overview.intro.2': 'Neighborhood Trust takes your privacy very seriously. Please read this notice carefully to understand what we do. ',
  'app.privacy-policy.overview.intro.3': 'If you do not want information about you to be used as described in this Privacy Policy, or if you disagree with any portion of this Privacy Policy or our information practices, you must stop, or refrain from, using the Services.',
  'app.privacy-policy.overview.intro.4': 'If you have any questions or concerns about our information practices, please contact us at {mailToSupportAction} or as otherwise set forth in the "How to Contact Us" section below.',
  'app.privacy-policy.overview.scope.sub-title': 'Scope',
  'app.privacy-policy.overview.sub-header': '1. Overview',
  'app.privacy-policy.passive-data-collection.1': 'As a general matter, we automatically collect certain information based on your use of and behavior on the Website and in connection with the Services. We use this information to analyze our customers\' demographics, interests and behavior to better understand and serve you and our customer base. We also analyze this information for security purposes, to protect the Services and customer information that we hold.',
  'app.privacy-policy.passive-data-collection.10': 'We may use web beacons or similar technologies, which help manage content on the Website and in the Services by tracking what content is effective. These technologies may be used to track the online movements of customers, but in contrast to cookies, which are stored on a computer hard drive, these technologies are embedded in the Website.',
  'app.privacy-policy.passive-data-collection.11': 'We may also use web beacons in our HTML-based emails to let us know which emails recipients have opened. This allows us to gauge the effectiveness of certain communications and the effectiveness of our marketing campaigns.',
  'app.privacy-policy.passive-data-collection.10': 'We may use web beacons or similar technologies, which help manage content on the Website and in the Services by tracking what content is effective. These technologies may be used to track the online movements of customers, but in contrast to cookies, which are stored on a computer hard drive, these technologies are embedded in the Website.',
  'app.privacy-policy.passive-data-collection.11': 'We may also use web beacons in our HTML-based emails to let us know which emails recipients have opened. This allows us to gauge the effectiveness of certain communications and the effectiveness of our marketing campaigns.',
  'app.privacy-policy.passive-data-collection.2': 'If the information we collect automatically identifies you or could be reasonably used to identify you, then it is treated as PII. Also, if we directly combine any information gathered through passive means with PII, we treat the combined information as PII under this Privacy Policy. But in many cases, the information that we collect passively is only used in de-identified or aggregated forms, which do not identify any individual.',
  'app.privacy-policy.passive-data-collection.3': 'We collect passive data in the following ways:',
  'app.privacy-policy.passive-data-collection.4': 'Mobile Device Information. When you download or use our Website from a mobile device, we receive information about your mobile device. We register your device, and we may use the identifier to send you push notifications about your transactions via the Services.',
  'app.privacy-policy.passive-data-collection.5': 'Log Files. Like most websites, we automatically receive and record certain information about our customers\' behavior on our Website and when using our Services. Our servers automatically gather technical information about our Website traffic and store it in log files. This information includes Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and other clickstream data. We aggregate this information with other pieces of information to analyze trends, to administer and improve the Services, for example to understand customers\' use of the Website and to make inventory and purchasing decisions that will best serve our customer base. Information from log files may also be used to determine general location information such as city, state or geographic area.',
  'app.privacy-policy.passive-data-collection.6': 'Hotjar. In accordance with the terms of this Privacy Policy, we use a technology service called Hotjar in order to better understand our users\' needs and to optimize this service and experience. It helps us better understand our users\' experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don\'t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users\' behavior and their devices (including, but not limited to, the device\'s IP address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match the information with other data with respect to an individual user. For further details, please see Hotjar\'s privacy policy by clicking on this {hotjarExternalLink}.',
  'app.privacy-policy.passive-data-collection.7': 'link',
  'app.privacy-policy.passive-data-collection.8': 'Cookies, Web Beacons and Similar Technologies. A cookie is a small text file that is stored on a computer for record-keeping or tracking purposes. Web beacons (also known as pixel tags or clear GIFs), are graphics that can record whether a person has accessed particular content. We use these and other similar technologies on the Website and in connection with our Services. Cookies we utilize may include session cookies, persistent cookies, or similar technologies. A session cookie expires when you close your browser. A persistent cookie is stored on your computer or device for an extended period of time. You can remove persistent cookies by following the directions provided in your Internet browser\'s "help" menu. You may also configure your browser to prevent cookies from being set on your computer. (Instructions should be provided in documentation for your browser.) If you reject cookies, your ability to use the Website and Services may be limited, and you may not be able to use certain features of the Website or Services. We may use cookies for the following purposes: ',
  'app.privacy-policy.passive-data-collection.9': 'To find our more information about cookies, including information about how to manage and delete cookies, please visit {allAboutCookiesLink} .',
  'app.privacy-policy.passive-data-collection.cookies.1': 'Essential cookies. ',
  'app.privacy-policy.passive-data-collection.cookies.2': 'Essential cookies are required for providing you with features or services that you have requested. For example, certain cookies enable you to log into secure areas of our Services. Disabling these cookies may make certain features and services unavailable.',
  'app.privacy-policy.passive-data-collection.cookies.3': 'Functional cookies. ',
  'app.privacy-policy.passive-data-collection.cookies.4': 'Functional cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Service. These cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).',
  'app.privacy-policy.passive-data-collection.cookies.5': 'Performance/analytical cookies. ',
  'app.privacy-policy.passive-data-collection.cookies.6': 'Performance/analytical cookies allow us to understand how visitors use our Service such as by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/analytical cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services\' content for those who engage with our advertising.',
  'app.privacy-policy.pii-bulletpoints.1': 'Social security numbers',
  'app.privacy-policy.pii-bulletpoints.2': 'Address and contact information',
  'app.privacy-policy.pii-bulletpoints.3': 'Date of birth',
  'app.privacy-policy.pii-bulletpoints.4': 'Demographic information',
  'app.privacy-policy.pii-bulletpoints.5' : 'Employer',
  'app.privacy-policy.pii-bulletpoints.6' : 'Income and expenses',
  'app.privacy-policy.pii-bulletpoints.7' : 'Bank account and balance information',
  'app.privacy-policy.pii-bulletpoints.8' : 'Information from the credit reporting agencies',
  'app.privacy-policy.scope.1': 'This Privacy Policy applies to information that we collect through the Website, and any of our other websites that provide a link to this Privacy Policy, as well as information collected through any of our products and services that are accessible via the Website, from you as an individual user ("you") of the Website.',
  'app.privacy-policy.scope.2': 'Please understand that, except as specifically described below, this Privacy Policy ',
  'app.privacy-policy.scope.3': 'does not ',
  'app.privacy-policy.scope.4': 'apply to information collected by third parties, such as other companies, or third-party websites or mobile applications, even if any are linked to or accessible through the Services. Further, any Services received in person may be subject to additional privacy terms and conditions, which will be provided to you in connection with such in-person Services. To the extent any provisions of this Privacy Policy conflict with any such privacy terms provided in connection with in-person Services, the provisions of such privacy terms shall control.',
  'app.privacy-policy.security.1': 'Information security: ',
  'app.privacy-policy.security.2': 'We take reasonable technical and physical security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These include encryption, internal reviews of our data collection, best physical and digital storage and processing practices and security measures, keeping up to date with patches on all relevant software, as well as working with reliable cloud providers to guard against unauthorized access to systems.',
  'app.privacy-policy.security.3': 'Our failure to enforce any provision of these Terms of Service shall not be construed as a waiver of that provision or any other provision. No waiver shall be effective against us unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. Except as expressly agreed by us and you in writing, these Terms of Service and the Privacy Policy constitute the entire agreement between you and us with respect to the subject matter, and supersedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter. These Terms of Service and the Privacy Policy will inure to the benefit of our successors, assigns, licensees, and sublicenses.',
  'app.privacy-policy.security.sub-header': '10. Security',
  'app.privacy-policy.third-party-advertisers.1': 'Third-party advertising companies may also use cookies, web beacons or similar technologies on the Website to collect information (such as click stream information) during your visits to our Website and other websites, as well as during your use of our Services, in order to help us provide you with advertisements about our Services that we believe are likely to be of interest to you.',
  'app.privacy-policy.third-party-advertisers.2': 'If you prefer not to have your information used for the purpose of serving you personalized advertising, you may also learn more about your choices or change your preferences at {optOutAdsLink}, {optOutNetworkAdvertisingLink}, {trusteLink}, from Google at {googleAdsLink} or {googlePoliciesLink}, from Facebook at {facebookAdsLink} or {facebookPolicyLink}, or if you are located in the European Union at {euroUnionOnlineChoicesLink}.',
  'app.privacy-policy.third-party-advertisers.euro-union-online-choices-link': 'www.youronlinechoices.eu/',
  'app.privacy-policy.third-party-advertisers.facebook-ads-link': 'www.facebook.com/about/ads',
  'app.privacy-policy.third-party-advertisers.facebook-policy-link': '/www.facebook.com/policy.php',
  'app.privacy-policy.third-party-advertisers.google-ads-link': 'www.google.com/settings/ads',
  'app.privacy-policy.third-party-advertisers.google-policies-link': 'policies.google.com/technologies/partner-sites',
  'app.privacy-policy.third-party-advertisers.opt-out-ads-link': 'optout.aboutads.info',
  'app.privacy-policy.third-party-advertisers.opt-out-networking-advertising-link': 'optout.networkadvertising.org',
  'app.privacy-policy.third-party-advertisers.truste-link': 'preferences-mgr.truste.com',
  'app.privacy-policy.third-party-websites.1': 'In the event that we provide links to otehr websites that we do not control, whether or not those other websites refer to our branding, this Privacy Policy does not apply to those other websites. Visitors to those websites are advised to carefully read the policies on those individual websites.',
  'app.privacy-policy.third-party-websites.sub-header': '5. Third Party Websites',
  'app.privacy-policy.your-choices.accessing-requesting-changes.1': 'If you are a customer of Neighborhood Trust, you may review and change your PII through your customer account.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.2': 'If you do not have a customer account with Neighborhood Trust, or if you cannot access or change any of your PII through your account, but you still wish to inquire about, access, correct, update, or delete PII that we may hold about you, please email us at {mailToSupportAction}. Please understand that, before responding to your request, we may ask you for additional information in order to verify your identity, or take other actions that we believe are appropriate in order to verify your identity.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.3':'Please also understand that we may not be able to alter or delete your PII and may retain information to provide you with requested services or fulfill obligations to you ,for security reasons, if we are required under applicable law or regulations, for internal business use or for other valid reasons. We are also not obligated to comply with requests that are unreasonable burdensome or expensive, or with requests that would interfere with the rights of another individual. In some circumstances, we may charge a reasonable fee to fulfill your request.',
  'app.privacy-policy.your-choices.accessing-requesting-changes.subtitle': 'Accessing and requesting changes to your PII',
  'app.privacy-policy.your-choices.communications-preferences.1': 'If you would like to change the types of communications you receive from us or how your receive communications from us, or if you would like to opt out of promotional communications, you may do so at any time by updating the communications preferences provided in your customer account.',
  'app.privacy-policy.your-choices.communications-preferences.2':'If you do not have a customer account, you may also use the unsubscribe links and instructions that will be present in any promotional emails or other communications that we send.',
  'app.privacy-policy.your-choices.communications-preferences.3' : 'Please understand that, even if you opt out of marketing or promotional communications, we may continue to send non-promotional communications such as confirmations of transactions, changes to the Services or other information about your use of the Services.',
  'app.privacy-policy.your-choices.communications-preferences.subtitle': 'Communications preferences:',
  'app.privacy-policy.your-choices.do-not-track.1' : 'Our Website and Services are not at this time configured to read or respond to "do not track" settings or signals in your browser headings.',
  'app.privacy-policy.your-choices.do-not-track.subtitle': '"Do Not Track" Settings:',
  'app.privacy-policy.your-choices.sub-header': '7. Your Choices',
  'app.registration.agreement.terms-link': 'terms of service',
  'app.registration.input.email': 'Email',
  'app.registration.input.first-name': 'First name',
  'app.registration.input.last-name': 'Last name',
  'app.registration.tos.agree': 'Agree',
  'app.registration.tos.disagree': 'Disagree',
  'app.report-or-appointment.agreement': 'By clicking the blue button below, you authorize TrustPlus to pull your credit report for the purposes stated above. To further provide you with support and monitor your progress, you authorize TrustPlus to obtain your credit report from Transunion® or any of the three credit reporting agencies for the duration of your engagement in TrustPlus services and for up to 26 months thereafter.',
  'app.report-or-appointment.go-to-credit-report-form': 'Get my credit report',
  'app.report-or-appointment.schedule-appointment': 'Skip this step',
  'app.report-or-appointment.text-1': 'Before meeting with your coach, the first step toward achieving your goals is knowing about your financial past and present. An easy way to do this is to look at insights from your credit report.',
  'app.report-or-appointment.text-2': 'With your permission, we can pull your TransUnion® credit report and show you insights that will help you gain an understanding of your financial life. This will be a “soft pull,” meaning it won’t impact your score.',
  'app.report-or-appointment.text-3': 'This service has been provided by {employer} at no cost to you. We will never share any of your financial information with your employer.',
  'app.report-or-appointment.title': 'Let\'s get started.',
  'app.report.additional-questions': 'Continue',
  'app.reset-new-password-form.title': 'Please make a new password.',
  'app.reset-password.instructions': 'We\'ve been doing some security updates and you\'ll have to reset your password in order to log in. We have sent you an email with instructions. It may take a few moments to show up in your account.',
  'app.reset-password.title': 'Hi, it looks like you\'ve been here before.',
  'app.round-robin.pick-a-time.dcl.accepted.text-1': 'Based on the answers you gave us, ',
  'app.round-robin.pick-a-time.dcl.accepted.text-2': 'we may have a solution ',
  'app.round-robin.pick-a-time.dcl.accepted.text-3': 'for your debt. Here are your next steps:',
  'app.round-robin.pick-a-time.dcl.accepted.text-4': 'Schedule a session. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-5': 'Together, you and your financial coach will discuss your debt in more detail and create a plan of action.',
  'app.round-robin.pick-a-time.dcl.accepted.text-6': 'Pull your credit report. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-7': 'This will be a soft pull and will not impact your score. Your report is an essential tool to make the most out of your first session and help TrustPlus determine the best path forward for your debt. ',
  'app.round-robin.pick-a-time.dcl.accepted.text-8': 'Our expert financial coaches will provide guidance and be your point person for debt consolidation questions.',
  'app.round-robin.pick-a-time.dcl.accepted.title': 'Eliminate your debt faster with debt consolidation',
  'app.round-robin.pick-a-time.dcl.description': 'Schedule a session to start your debt consolidation journey!',
  'app.round-robin.pick-a-time.dcl.not-accepted.description': 'Schedule a session to start your debt consolidation journey!',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-1': 'Thank you for answering the pre-screener questions!',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-2': 'Based on the answers you gave us, ',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-3': 'we may have a solution ',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-4': 'for you.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-5': 'Schedule a session today to explore this further and create a plan of action to tackle your debt.',
  'app.round-robin.pick-a-time.dcl.not-accepted.text-6': 'By scheduling a session, your financial coach will answer your questions  and work with you to achieve your goals.',
  'app.round-robin.pick-a-time.dcl.not-accepted.title': 'Explore debt reduction with our trusted financial coach experts',
  'app.round-robin.pick-a-time.instruction1': 'A financial coach will be your guide through your financial wellness journey. They’ll be able to answer your financial questions.',
  'app.round-robin.pick-a-time.instruction2': 'Each session will take 30 minutes. All times are local based on your location.',
  'app.round-robin.pick-a-time.pull-cr': 'You can make the most out of your session by pulling your credit report.',
  'app.round-robin.pick-a-time.select-by-availability': 'SCHEDULE BY AVAILABILITY',
  'app.round-robin.pick-a-time.select-date-instruction': 'Select a specific date when you are available, and then select from a list of timeslots.',
  'app.round-robin.pick-a-time.title': 'Let’s start by scheduling a financial coaching session.',
  'app.saml-relay.title': 'logging you in...',
  'app.schedule-confirmation.continue.title': 'Your financial profile: ',
  'app.schedule-confirmation.counselor.bubble.text': 'On the day of the appointment, I’ll text you a video call link or call you from my dedicated number, a 929 area code.',
  'app.schedule-confirmation.description-1': 'On the day of the appointment, *coach first name* will text you a video link or call you from a dedicated number with a 929 area code.',
  'app.schedule-confirmation.description-2': 'In the meantime, tell us about your finances so we can get the most out of your meeting.',
  'app.schedule-confirmation.percent.complete-text': 'complete',
  'app.schedule-confirmation.pull.report.button': 'Pull Credit Report',
  'app.schedule-confirmation.title': 'Great! Your session is scheduled!',
  'app.second-chance-report.counselor.bubble.text': 'Just one step to go. Pull your credit report to see a summary of your debts and discuss your credit questions with your coach.',
  'app.second-chance-report.pull.report.button': 'Pull credit report',
  'app.second-chance-report.skip.button': 'Skip for now',
  'app.second-chance-report.skip.credit.report.forever': 'I do not want to pull my credit. Please do not show me this message again.',
  'app.second-chance-report.title': 'Almost done.',
  'app.shift-desktop-and-mobile-provider.view-bio': 'View full bio',
  'app.spinner.loading': 'Loading',
  'app.shift-desktop-and-mobile-provider.more': 'more',
  'app.shift-desktop-and-mobile-provider.less': 'less',
  'app.shift-desktop-and-mobile-provider.view-bio': 'View full bio',
  'app.shift-table.dayname.Sunday': 'Sunday',
  'app.shift-table.dayname.Monday': 'Monday',
  'app.shift-table.dayname.Tuesday': 'Tuesday',
  'app.shift-table.dayname.Wednesday': 'Wednesday',
  'app.shift-table.dayname.Thursday': 'Thursday',
  'app.shift-table.dayname.Friday': 'Friday',
  'app.shift-table.dayname.Saturday': 'Saturday',
  'app.shift-table.dayname.Sun': 'Sun.',
  'app.shift-table.dayname.Mon': 'Mon.',
  'app.shift-table.dayname.Tue': 'Tues.',
  'app.shift-table.dayname.Wed': 'Wed.',
  'app.shift-table.dayname.Thu': 'Thurs.',
  'app.shift-table.dayname.Fri': 'Fri.',
  'app.shift-table.dayname.Sat': 'Sat.',
  'app.shift-table.monthname.January': 'January',
  'app.shift-table.monthname.February': 'February',
  'app.shift-table.monthname.March': 'March',
  'app.shift-table.monthname.April': 'April',
  'app.shift-table.monthname.May': 'May',
  'app.shift-table.monthname.June': 'June',
  'app.shift-table.monthname.July': 'July',
  'app.shift-table.monthname.August': 'August',
  'app.shift-table.monthname.September': 'September',
  'app.shift-table.monthname.October': 'October',
  'app.shift-table.monthname.November': 'November',
  'app.shift-table.monthname.December': 'December',
  'app.standalone-credit-report-thank-you.title': 'Thanks for authorizing a credit report pull.',
  'app.standalone-credit-report.text': 'Thank you. Your coach will follow-up with you on the items on your credit report. If you would like to see what\'s on your credit report, please login! Get started by entering your email.',
  'app.standalone-credit-report.title': 'Login to see your credit report.',
  'app.tempcode.alt-link': 'Resend temporary code',
  'app.tempcode.input.tempcode': 'Temporary code',
  'app.tempcode.instruction1': 'A temporary code was sent to your email. Enter it below.',
  'app.tempcode.resent.code.message' : 'Didn’t receive the code to your phone? Sorry about that! Check your email now for the code.',
  // 'app.tempcode.resent.code.message' : 'Didn’t receive the code to your phone? Sorry about that! Check your email now for the code.',
  'app.tempcode.resent.code.message' : 'Didn’t receive the code to your phone? Sorry about that! Check your email now for the code.',
  // 'app.tempcode.resent.code.message' : 'Didn’t receive the code to your phone? Sorry about that! Check your email now for the code.',
  'app.tempcode.title': 'We sent a temporary code!',
  'app.terms.agree': 'I agree',
  'app.terms.go-back': 'Go back',
  'app.terms.privacy.section.6.header': 'How to contact us: ',
  'app.terms.privacy.section.6.text.1a': 'If you have questions, concerns or feedback regarding these Terms of Service, you can send an email to us at {mailToSupportAction} or by postal mail at:',
  'app.terms.privacy.section.6.text.1': 'Neighborhood Trust Financial Partners',
  'app.terms.privacy.section.6.text.1a': 'If you have questions, concerns or feedback regarding these Terms of Service, you can send an email to us at {mailToSupportAction} or by postal mail at:',
  'app.terms.privacy.section.6.text.2': '530 West 166th St, 4th floor',
  'app.terms.privacy.section.6.text.3': 'New York, NY 10032',
  'app.terms.privacy.section.6.text.4': 'The Neighborhood Trust Terms of Service & Privacy Policy can also be found at {externalTermsOfService}',
  'app.terms.terms.intro.1': 'Thank you for using the services of Neighborhood Trust Financial Partners, LLC and its subsidiaries, partners and affiliates (which may include designations such as “we”, “us”, or “Neighborhood Trust” within this document and the application, and on our website and related marketing materials). Our services (the “Services”) are provided expressly for the purposes of financial capability building on behalf of employees of our partners, and other clients enrolled with us directly.',
  'app.terms.terms.intro.2': 'The terms contained in this document (the “Terms of Service”), as amended from time to time, apply to all individuals who use Neighborhood Trust’s website or web applications, set up an account with Neighborhood Trust, or otherwise utilize Neighborhood Trust’s Services (each a “User”) and govern each User’s access to and use of this website, at {neighborhoodTrustLink}, and any of our other websites that directly link to these Terms of Service, as well as any of our content, tools, functions, products, or services that are accessible from such websites (collectively, the “Site”).',
  'app.terms.terms.intro.3a': 'Please read these Terms of Service carefully upon accessing or using the Website. ',
  'app.terms.terms.intro.3b': 'By using the Site, or by clicking to accept or agree to these Terms when this option is made available to you,  you accept and agree to be bound by these Terms, and these Terms form a binding contract entered into between you and Neighborhood Trust.',
  'app.terms.terms.intro.3c': 'If you, as an individual, are acting for or on behalf of a company, organization, or other entity, such as your employer, then by accessing or using the Website: (i) you represent that you are fully authorized to access or use the Website; (ii) you agree to be bound by these Terms of Service on behalf of yourself and such entity; and (iii) “you” shall refer collectively to you and such entity.',
  'app.terms.terms.intro.4': 'If you do not accept or agree to these Terms of Service, then you are not authorized to, and must not, access or use the Services or Website.',
  'app.terms.terms.intro.5': 'You may not use any of the Services and you may not accept these Terms of Service if you are not legally authorized to accept and be bound by these terms or are not at least 18 years of age and, in any event, of a legal age to form a binding contract with Neighborhood Trust.',
  'app.terms.terms.intro.6': 'Before you continue, you should print or save a local copy of these Terms of Service for your records.',
  'app.terms.terms.intro.7' : 'The sole purpose of our Services is to assist Users in building financial capability, including but not limited to cash flow management, financial product selection, budget and expense analysis, and general financial advice. These services are only available to residents of the United States.',
  'app.terms.terms.section.1.header': 'Your right to opt-out',
  'app.terms.terms.section.1.text.1': 'Participation in these Services is voluntary, and you can revoke any of the permissions within this document at any time, including before your participation in these Services begins, by notifying Neighborhood Trust in writing. Please refer to the end of this document for information on how to contact us.',
  'app.terms.terms.section.1b.header': 'Description of the services',
  'app.terms.terms.section.1b.text.1': 'The Services consist of general personal finance counseling and financial information management services that allows you to track your financial information, while receiving support and advice on credit, debt, budgeting, and saving. The Neighborhood Trust Services are meant to provide you with your information to allow you to manage your finances at no cost to you. ',
  'app.terms.terms.section.1b.text.2': 'These Services may be available to you via your employer as a voluntary employee benefit, or through a third-party referral partner. Neighborhood Trust may receive compensation from employers and referral partners in order to provide our confidential financial counseling services. Your employer/plan sponsor determines your eligibility for each benefit. If you are enrolled in the Services under an employer or third party sponsored program, the provisions of these Terms of Service as applied to you may vary in one or more ways from those set forth above, but only if you are notified in writing.',
  'app.terms.terms.section.1b.text.3': 'The Neighborhood Trust Services may also include a service that provides your Transunion credit summary and FICO credit score.',
  'app.terms.terms.section.1b.text.4': 'The Site provides various tools and functions through the website and web application including a personal information management service that allows you to consolidate and track certain personal information.',
  'app.terms.terms.section.1b.text.5': 'The Services may also present you information relating to third party products or services that you may be interested in. The Services may also provide you general tips, action items and educational material, including with respect to designing a personal financial plan tailored to your specific needs and situation. Unless expressly noted otherwise, Neighborhood Trust receives no compensation for providing links to and explaining the benefits of any specific financial product or service provided by a third party. ',
  'app.terms.terms.section.2.header': 'Credit report authorization',
  'app.terms.terms.section.2.text.1': 'When you agree to the Terms of Service and click “Get My Credit Report”, you give Neighborhood Trust permission to obtain your credit report from any of the three credit reporting agencies for the duration of your use of our Sites or Services and for up to twenty six (26) months thereafter, for program evaluation purposes. Your credit score will not be affected.',
  'app.terms.terms.section.3.header': 'Permitted Uses',
  'app.terms.terms.section.3.subheader.1': 'Limited license: ',
  'app.terms.terms.section.3.subheader.2': 'Approved uses: ',
  'app.terms.terms.section.3.subheader.3': 'Decency: ',
  'app.terms.terms.section.3.subheader.4': 'Legality: ',
  'app.terms.terms.section.3.subheader.5': 'Commercial use: ',
  'app.terms.terms.section.3.subheader.6': 'Automated use: ',
  'app.terms.terms.section.3.subheader.7': 'Secure use: ',
  'app.terms.terms.section.3.subheader.8': 'Age restrictions: ',
  'app.terms.terms.section.3.subheader.9': 'Residency: ',
  'app.terms.terms.section.3.text.1': 'Neighborhood Trust is providing you with a revocable usage license to use the Site and Services, and all terms, copyrights, trademarks, service marks, brand names, trade names, logos, designs, graphics, illustrations, images, videos, photographs, and all intellectual property of any kind whatsoever utilized therein, solely for the purpose of receiving and using the Services and other approved uses of the Site. ',
  'app.terms.terms.section.3.text.2': 'Our Services are provided to offer actionable financial guidance. The Site may only be used by you in furtherance of the Services. The Site may only be used by approved and invited Users at Neighborhood Trust’s discretion. You may not register for more than one account, or register for an account on behalf of an individual other than yourself or on behalf of any group or entity. Your right to access and use the Site and the Services is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use the Site and Services for lawful purposes. Accurate records enable Neighborhood Trust to provide the Services to you. You must provide true, accurate, current and complete information about your accounts maintained at other websites. You may not misrepresent your Content and Account Information (each as defined below) provided to Neighborhood Trust. In order for the Services to function effectively, you must also keep such Content and Account Information up to date and accurate. If you do not do this, the accuracy and effectiveness of the Services will be affected. You represent that you are a legal owner of, and that you are authorized to provide us with, all registration information, Account Information and other information necessary to facilitate your use of the Services.',
  'app.terms.terms.section.3.text.3': 'You will not use profane, derogatory or disrespectful language in your submissions to, and correspondence within, the Site. Neighborhood Trust may suspend or ban any User account that, in its sole determination, does not comply with these Terms of Service.',
  'app.terms.terms.section.3.text.4': 'You will not use the Services or Site in a way that violates any state, federal or international laws or treaties.',
  'app.terms.terms.section.3.text.5': 'Users will not use our services nor any of its parts or features to generate commercial revenue without the express written consent of Neighborhood Trust.',
  'app.terms.terms.section.3.text.6': 'You agree that you will not:',
  'app.terms.terms.section.3.text.6a': 'Use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor Neighborhood Trust, the Site or Services, or any portion thereof, without Neighborhood Trust’s express prior written consent, which may be withheld in Neighborhood Trust’s sole discretion;',
  'app.terms.terms.section.3.text.6b': 'Use or attempt to use any engine, software, tools, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search Neighborhood Trust, other than the search engines and search agents available through the Services and other than generally available third-party web browsers (such as Microsoft Internet Explorer and Chrome);',
  'app.terms.terms.section.3.text.6c': 'Post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interfere with the proper working of Neighborhood Trust’s Sites or the Services; or',
  'app.terms.terms.section.3.text.6d': 'Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of Neighborhood Trust or the Services.',
  'app.terms.terms.section.3.text.6a': 'Use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor Neighborhood Trust, the Site or Services, or any portion thereof, without Neighborhood Trust’s express prior written consent, which may be withheld in Neighborhood Trust’s sole discretion;',
  'app.terms.terms.section.3.text.6b': 'Use or attempt to use any engine, software, tools, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search Neighborhood Trust, other than the search engines and search agents available through the Services and other than generally available third-party web browsers (such as Microsoft Internet Explorer and Chrome);',
  'app.terms.terms.section.3.text.6c': 'Post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interfere with the proper working of Neighborhood Trust’s Sites or the Services; or',
  'app.terms.terms.section.3.text.6d': 'Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of Neighborhood Trust or the Services.',
  'app.terms.terms.section.3.text.7': 'You are responsible for maintaining the privacy and security of your passwords and other security information used in connection with the Services or Site. You are also responsible for maintaining the integrity of your hardware and operating system to prevent viruses, spyware or other malicious software from misappropriating your password information or data. You should exercise caution when accessing the Services or Site from public or shared computers so that others are not able to access your personal information. Neighborhood Trust bears no responsibility for unauthorized access to your Neighborhood Trust account as a result of your failure to follow appropriate security precautions. Users will not share, post, or otherwise leak their security credentials. If you should become aware of any unauthorized use of your account or user information, or if you otherwise believe that your account has been compromised or there has been a breach in security, you agree to immediately notify Neighborhood Trust by e-mail to: {mailToSupportAction} .',
  'app.terms.terms.section.3.text.8': 'In order to use Neighborhood Trust’s services you must be 18 years of age or older.',
  'app.terms.terms.section.3.text.9' : 'You must be a resident of the United States to use our services.',
  'app.terms.terms.section.4.header': 'Communications',
  'app.terms.terms.section.4.subheader.1': 'Notices, alerts, and messages: ',
  'app.terms.terms.section.4.subheader.2': 'Zoom: ',
  'app.terms.terms.section.4.subheader.2a': 'Google: ',
  'app.terms.terms.section.4.subheader.2b' : 'Feedback: ',
  'app.terms.terms.section.4.subheader.2a': 'Google: ',
  'app.terms.terms.section.4.subheader.2b' : 'Feedback: ',
  'app.terms.terms.section.4.text.1': 'You will receive voluntary notifications, automatic alerts, and other communications from us related to the business purposes of the Site and Services. Automatic alerts may be sent to you following certain changes to your account or information, such as a change in your Content (defined below) or account information provided to Neighborhood Trust. These notices may be provided by email, SMS, MMS, telephone calls, or by other means.',
  'app.terms.terms.section.4.text.1a': 'Voluntary notifications may be turned on by default as part of the Services. Such notifications can be deactivated by you in communications with your Neighborhood Trust counselor. Neighborhood Trust may add new notifications from time to time, or cease to provide certain notifications at any time upon its sole discretion.',
  'app.terms.terms.section.4.text.1b': 'You understand and agree that any alerts or notifications provided to you through the Services or Site may be delayed or prevented by a variety of factors. Neighborhood Trust may make commercially reasonable efforts to provide notifications or alerts in a timely manner with accurate information, but cannot guarantee the delivery, timeliness, or accuracy of the content of any alert. Neighborhood Trust shall not be liable for any delays, failure to deliver, or misdirected delivery of any alert, for any errors in the content of an alert, or for any actions taken or not taken by you or any third party in reliance on an alert.',
  'app.terms.terms.section.4.text.1c': 'Electronic notifications will be sent to the email address or mobile number you have provided for the Services. If your email address or your mobile number changes, you are responsible for informing us of that change. Alerts may also be sent to a mobile device that accepts text messages. Changes to your email address and mobile number will apply to all of your alerts.',
  'app.terms.terms.section.4.text.1d': 'Notifications are not encrypted. They may include your Login ID and some information about your accounts. If you request a password reset, we may email a temporary password to you. Such requests do not constitute an alert. Anyone with access to the email account you provide to us will be able to view the content of these notifications. You may disable notifications at any time by emailing {mailToSupportAction} .',
  'app.terms.terms.section.4.text.1a': 'Voluntary notifications may be turned on by default as part of the Services. Such notifications can be deactivated by you in communications with your Neighborhood Trust counselor. Neighborhood Trust may add new notifications from time to time, or cease to provide certain notifications at any time upon its sole discretion.',
  'app.terms.terms.section.4.text.1b': 'You understand and agree that any alerts or notifications provided to you through the Services or Site may be delayed or prevented by a variety of factors. Neighborhood Trust may make commercially reasonable efforts to provide notifications or alerts in a timely manner with accurate information, but cannot guarantee the delivery, timeliness, or accuracy of the content of any alert. Neighborhood Trust shall not be liable for any delays, failure to deliver, or misdirected delivery of any alert, for any errors in the content of an alert, or for any actions taken or not taken by you or any third party in reliance on an alert.',
  'app.terms.terms.section.4.text.1c': 'Electronic notifications will be sent to the email address or mobile number you have provided for the Services. If your email address or your mobile number changes, you are responsible for informing us of that change. Alerts may also be sent to a mobile device that accepts text messages. Changes to your email address and mobile number will apply to all of your alerts.',
  'app.terms.terms.section.4.text.1d': 'Notifications are not encrypted. They may include your Login ID and some information about your accounts. If you request a password reset, we may email a temporary password to you. Such requests do not constitute an alert. Anyone with access to the email account you provide to us will be able to view the content of these notifications. You may disable notifications at any time by emailing {mailToSupportAction} .',
  'app.terms.terms.section.4.text.2': 'Portions of the Services may be provided over Zoom, and any personal information communicated over Zoom will be subject to Zoom’s privacy policy and information security controls. Zoom’s policies can be found at {zoomUrl} .',
  'app.terms.terms.section.4.text.2a': 'Portions of the Services may be provided over Google Meet, and any personal information communicated over Google Meet will be subject to Google’s privacy policy and information security controls. Google’s policies can be found at {googleUrl} .',
  'app.terms.terms.section.4.text.2b': 'Any feedback, questions, comments, suggestions, ideas, or the like that you send to Neighborhood Trust (collectively, “Feedback”) will be treated as being non-confidential and nonproprietary with respect to you, and Neighborhood Trust will be free to use such information for any purpose whatsoever, including developing and marketing products and services incorporating the information, without payment of royalties or other consideration to you.',
  'app.terms.terms.section.4.text.2a': 'Portions of the Services may be provided over Google Meet, and any personal information communicated over Google Meet will be subject to Google’s privacy policy and information security controls. Google’s policies can be found at {googleUrl} .',
  'app.terms.terms.section.4.text.2b': 'Any feedback, questions, comments, suggestions, ideas, or the like that you send to Neighborhood Trust (collectively, “Feedback”) will be treated as being non-confidential and nonproprietary with respect to you, and Neighborhood Trust will be free to use such information for any purpose whatsoever, including developing and marketing products and services incorporating the information, without payment of royalties or other consideration to you.',
  'app.terms.terms.section.4.text.3': 'When you use the Services or Site, you will create a personal profile and manually enter information, including, without limitation, your financial information (budget, expenses, income, etc.). You warrant and represent that you own or otherwise control the rights to all of this information provided to Neighborhood Trust through your use of the Services or Site (collectively, “Content”). You are responsible for any Content that may be lost or unrecoverable through your use of the Services. You agree that you will not use the Services or Site to share, store, or in any way distribute Content that is not in accordance with the law. Neighborhood Trust is not responsible for the Content or data you submit on the website.',
  'app.terms.terms.section.4a.header': 'Your content',
  'app.terms.terms.section.4b.3.bulletpoints.a': 'Your computer may temporarily store copies of such contents of the Site in RAM incidental to your accessing and viewing those contents.',
  'app.terms.terms.section.4b.3.bulletpoints.b': 'You may store files that are automatically cached by your browser for display enhancement purposes.',
  'app.terms.terms.section.4b.3.bulletpoints.c': 'You may print or download a reasonable number of pages of the Site or a reasonable amount of materials from the Site, in either case solely for private use or use for internal business purposes and not for further reproduction, publication, or distribution.',
  'app.terms.terms.section.4b.4.bulletpoints.a': 'Modify copies of any materials from the Site.',
  'app.terms.terms.section.4b.4.bulletpoints.b': 'Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Site.',
  'app.terms.terms.section.4b.header': 'Intellectual Property',
  'app.terms.terms.section.4b.subheader.1': 'Trademarks.',
  'app.terms.terms.section.4b.text.1': 'The Site, Services and all of their contents (including, but not limited to, all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Neighborhood Trust, its licensors, or other providers of such material and may be protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.',
  'app.terms.terms.section.4b.text.2': 'No right, title, or interest in or to the Site, Services or any contents or materials on the Site or provided through the Services is granted or transferred to you except as expressly set forth herein, and all rights not expressly granted herein are reserved by Neighborhood Trust. Any use of the Site or Services not expressly permitted by these Terms of Service constitutes a breach of these Terms of Service and may violate copyright, trademark, and other laws.',
  'app.terms.terms.section.4b.text.3': 'These Terms of Service permit you to use the Site and Services for private use or use for internal business purposes only. Except as expressly provided herein, you shall not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit the Site or Services, or any of the contents thereof, except as follows:',
  'app.terms.terms.section.4b.text.4': 'You must not: ',
  'app.terms.terms.section.4b.text.5': 'If you print, copy, modify, download, publicly display or distribute, or otherwise use any contents or materials from the Site or Services in breach of these Terms, we may immediately terminate your right to use the Site and Services, and you must, at our option, return or destroy any and all copies of contents or materials from the Site or Services in your possession.',
  'app.terms.terms.section.4b.text.6': ' The name Neighborhood Trust, the Neighborhood Trust logos, and all related names, logos, product and service names, designs, and slogans are trademarks of Neighborhood Trust or its affiliates or licensors.',
  'app.terms.terms.section.4b.text.7': 'You shall not use such marks without our prior written permission. All other names, logos, product and service names, designs, and slogans on this Site or Services are the trademarks of their respective owners.',
  'app.terms.terms.section.4c.1.bulletpoints.a': 'Take any action with respect to any user conduct that we deem necessary or appropriate in our sole discretion, including if we believe that such user conduct violates these Terms of Service, including those prohibited uses described herein, or that such user conduct infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Site or the public, or could create liability for Neighborhood Trust.',
  'app.terms.terms.section.4c.1.bulletpoints.b': 'Disclose your identity or other information about you to any third party who claims that your use of the Services or Site violates their rights, including their intellectual property rights or their right to privacy.',
  'app.terms.terms.section.4c.1.bulletpoints.c': 'Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site or Services.',
  'app.terms.terms.section.4c.1.bulletpoints.d': 'Terminate or suspend your access to all or part of the Site or Services for any or no reason, including, without limitation, any violation of these Terms of Service.',
  'app.terms.terms.section.4c.header': 'Monitoring and Enforcement',
  'app.terms.terms.section.4c.text.1': 'We have the right to: ',
  'app.terms.terms.section.4c.text.2': 'Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of any Users of the Site or Services, or other information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS NEIGHBORHOOD TRUST AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY NEIGHBORHOOD TRUST DURING, OR AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER NEIGHBORHOOD TRUST OR LAW ENFORCEMENT AUTHORITIES.',
  'app.terms.terms.section.4c.text.3': 'However, we do not undertake to review all material before it is posted on the Site, and cannot ensure prompt removal of objectionable material from the Site. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We shall have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.',
  'app.terms.terms.section.4d.header': 'Confidentiality',
  'app.terms.terms.section.4d.text.1': 'Neighborhood Trust and their counselors will not at any time or in any manner, either directly or indirectly, use for the personal benefit of Neighborhood Trust, or divulge, disclose, or communicate in any manner information that is personal to the User. Except as otherwise set forth herein or in the Privacy Policy, Neighborhood Trust will protect your information and treat it as strictly confidential. This provision shall continue to be effective after the termination of your engagement with the Services for as long as required by applicable law. Your financial information may be aggregated with others and shared with third parties for research, evaluation, marketing, and other reasonable purposes. This aggregate information does not identify particular Users or otherwise allow anyone to recover sensitive information about individual Users. For more information regarding the confidentiality of information provided by you, please refer to our Privacy Policy, available at {privacyPolicyUrl}.',
  'app.terms.terms.section.4d.text.2': 'this link',
  'app.terms.terms.section.4e.header': 'Privacy and Information Practices',
  'app.terms.terms.section.4e.text.1':'We value individual privacy, and we are committed to protecting individuals’ information. All information collected through the Site and Services is subject to our Privacy Policy, available at {privacyPolicyUrl}. By accessing or using the Site or Services, or otherwise agreeing to these Terms of Service, you acknowledge that you have read and understand the terms of the Privacy Policy and consent to the treatment of your information in accordance with the Privacy Policy.',
  'app.terms.terms.section.4f.header': 'Third Party Coordination',
  'app.terms.terms.section.4f.text.1': 'At times, we may provide you with links to third party sites, products, services, features, online communities, or applications. Users are responsible for individual terms of use with third parties they contact through or connect to using our Services. If you decide to use any of these other services, additional terms and conditions and separate fees may apply. If you decide to use any third party products or access any third party websites, you are responsible for reviewing the third party’s separate product terms, website terms and privacy policies. Neighborhood Trust does not endorse, warrant or guarantee the products or services available through any such offers, and Neighborhood Trust is not an agent or broker or otherwise responsible for the activities or policies of those web sites. Neighborhood Trust does not guarantee that the third-party offer information displayed through the Services are actually the terms that may be offered to you if you pursue the offer or that they are the best terms, lowest rates available in the market, or otherwise suitable for you. You must complete your own review and due diligence of any third-party offers, products or services, and Neighborhood Trust disclaims any express or implied obligation to do so.',
  'app.terms.terms.section.5.header': 'Disclaimer of Warranties',
  'app.terms.terms.section.5.text.1': 'TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND ALL INFORMATION PRESENTED VIA THE SITE OR SERVICES ARE PROVIDED “AS IS” AND “WITH ALL FAULTS,” AND NEIGHBORHOOD TRUST HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY NATURE, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, REGARDING THE SITE AND SERVICES AND ALL INFORMATION PRESENTED VIA THE SITE OR SERVICES, AND NEIGHBORHOOD TRUST SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, NEIGHBORHOOD TRUST MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION PRESENTED VIA THE SITE OR SERVICES; AVAILABILITY, SECURITY OR PERFORMANCE OF THE SITE OR SERVICES; EXISTENCE OR ABSENCE OF ANY DEFECTS IN THE SITE OR SERVICES; OR COMPATIBILITY OF THE SITE WITH ANY EQUIPMENT OR SOFTWARE.',
  'app.terms.terms.section.6.header': 'Limitation of Liability',
  'app.terms.terms.section.6.subheader.1': 'Indemnification',
  'app.terms.terms.section.6.subheader.2': 'No Legal, Tax, or Investment Advice: ',
  'app.terms.terms.section.6.subheader.3': 'Change and termination: ',
  'app.terms.terms.section.6.subheader.4': 'Personal mobile phone usage/data plan: ',
  'app.terms.terms.section.6.subheader.5': 'Governing law: ',
  'app.terms.terms.section.6.text.1': 'TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL NEIGHBORHOOD TRUST OR ANY OF ITS REPRESENTATIVES BE LIABLE TO YOU OR TO ANY THIRD PARTY UNDER OR IN CONNECTION WITH THESE TERMS OF SERVICE OR THEIR SUBJECT MATTER, UNDER ANY LEGAL THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, FOR ANY (A) CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES; (B) YOUR USE OF THE SITE OR SERVICES, OR ANY IMPAIRMENT, DELAY OR OTHER INTERRUPTION IN THE SITE, THE SERVICES OR YOUR ABILITY TO USE THE SAME; (C) LOSS OF BUSINESS, REVENUE OR PROFIT, DIMINUTION IN VALUE OR OTHER ECONOMIC LOSS; (D) HARM TO REPUTATION OR BUSINESS GOODWILL; OR (E) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY; (F) CONTENT, ACCURACY OR COMPLETENESS, AND ANY ERRORS IN, OR OMISSIONS FROM, THE MATERIALS INCLUDED IN OR AVAILABLE THROUGH THE SITE OR SERVICES; OR (G) CONTENT RETRIEVED FROM THE INTERNET EVEN IF RETRIEVED OR LINKED TO, FROM, OR WITHIN THE SITE OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER NEIGHBORHOOD TRUST OR ANY ITS REPRESENTATIVES WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.',
  'app.terms.terms.section.6.text.2': 'IN NO EVENT SHALL THE TOTAL AND AGGREGATE LIABILITY OF NEIGHBORHOOD TRUST TO YOU UNDER OR IN CONNECTION WITH THESE TERMS OF SERVICE OR THEIR SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED FIFTY U.S. DOLLARS ($50). ALL CLAIMS AGAINST NEIGHBORHOOD TRUST WITH RESPECT TO ANY LIABILITY RELATED TO THESE TERMS OF SERVICE SHALL BE AGGREGATED TO DETERMINE SATISFACTION OF SUCH LIMIT, AND THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE OR EXTEND THE FOREGOING LIMITATION. THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.',
  'app.terms.terms.section.6.text.3': 'You shall indemnify, defend and hold harmless Neighborhood Trust and its affiliates, and each of its and their respective officers, directors, employees, agents, successors and assigns (each, an “Indemnitee”) from and against any and all losses incurred by such Indemnitee in connection with any claim or action by a third party arising out of or relating to any: (i) content that you upload, post, email, transmit or otherwise make available via the Site; (ii) your breach of any of your representations, warranties, covenants or obligations under these Terms of Service; or (iii) negligence or any more culpable act or omission (including recklessness or willful misconduct) by you in connection with these Terms of Service or their subject matter.',
  'app.terms.terms.section.6.text.4': 'Neighborhood Trust counselors are not investment advisors, brokers, tax advisors, or Certified Financial Planners™. The Services and Site are only intended to provide you with support and assist in your financial decision-making and is broad in scope. You understand that the Services, Site and any other materials presented by Neighborhood Trust to you in connection with the Services or Site does not constitute any legal, tax, business or investment advice of any kind. No advice given by Neighborhood Trust to you as part of the Services or Site shall constitute an offer or solicitation in any jurisdiction to purchase or sell any securities of any kind. Nothing in these Terms of Service shall obligate or permit Neighborhood Trust to provide investment advice or investment recommendations to any User. We strongly encourage Users to consider obtaining additional information from their accountant or consult such legal, tax and investment advisors who are fully aware of their individual circumstances. You also understand that Neighborhood Trust is not a “credit repair organization” nor do we sell services that claim to improve or fix a User’s credit record, credit history, or credit rating for a fee. You agree that the use of the Sites and Service is at your sole risk.',
  'app.terms.terms.section.6.text.5': 'Neighborhood Trust may modify or update these Terms of Service as needed and desired. Any and all changes to these Terms of Service may be provided to you by electronic means (i.e. via email or by posting the information on the Sites). The Terms of Service will always indicate the date it was last revised. Your usage after any update constitutes continued agreement.',
  'app.terms.terms.section.6.text.6': 'Neighborhood Trust reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Services or Site (or any part thereof) with or without notice. We can also terminate the agreement with you in the event you violate these Terms of Service in our judgement. ',
  'app.terms.terms.section.6.text.7': 'Use of the Services or Site may be available through a compatible mobile device, Internet and/or network access and may require software. Any mobile phone usage/data used to engage with the program will not be reimbursed by your employer or Neighborhood Trust. You agree that you are solely responsible for these requirements, including any applicable changes, updates and fees as well as the terms of your agreement with your mobile device and telecommunications provider.',
  'app.terms.terms.section.6.text.8': 'You agree that all terms and provisions, hereof, shall be governed, construed, and enforced in accordance with the laws of the state of New York.',
  'app.terms.terms.title.revision.date': 'Last Revised: 10/2020',
  'app.terms.title': 'Terms of Service',
  'app.terms.tos-pp-intro-text': 'Before proceeding, please review our Terms of Service and Privacy Policy.',
  'app.welcome.input.email': 'Email',
  'app.welcome.instruction1': 'Get started today by entering your email.',
  'app.welcome.logout.instruction1': 'Log back in by entering your email.',
  'app.welcome.logout.title': 'Logged out',
  'app.welcome.timeout.instruction1': 'Log back in by entering your email.',
  'app.welcome.timeout.title': 'Logged out due to inactivity',
  'app.welcome.title': 'Welcome!',
}

export default en