import React, {useState} from 'react'
import FormattedMessage from './helpers/FormattedMessage'
import UseWindowDimensions from "./hooks/use-window-dimensions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function IntroductionPageCounselors(props) {

  const [currentIndex, setCurrentIndex] = useState(0)
  const [offset, setOffset] = useState(0)
  const {height, width} = UseWindowDimensions()

  const bio = (index) => {
    if (props.locale === 'en-US') {
      return props.activeCounselors[index].aboutMe
    } else {
      return props.activeCounselors[index].aboutMeSpanish
    }
  }

  const coachImageClick = (e, index) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()

    setCurrentIndex(index)
  }

  const handleCounselorImages = (index) => {
    const counselorImages = {
      'Yermenos': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Shanick.jpg',
      'Ordonez': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Tiffany.jpg',
      'Crump': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Janet.jpg',
      'Nussbaum': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Elise.jpg',
      'Gregory': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Adrianna.jpg',
      'Douglas': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Dametria.jpg',
      'Saavedra': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Bernard.jpg',
      'Elayna Alexander': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Elayna.jpg',
      'Hannah': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Ashley.jpg',
      'Kinney': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Samantha.jpg',
      'Paez': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Ana.jpg',
      'Gomez': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Rodrigo.jpg',
      // Below names are for testing only.
      // Youtube URLs are the first 5 Youtube URLs from the above counselors
      'Montague': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Shanick.jpg',
      'Kendrick': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Bernard.jpg',
      'Antrobus' : 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Elise.jpg',
      'Mancke': 'https://gotrustplus-app-images.s3.us-west-1.amazonaws.com/staff_images/Samantha.jpg',
    }
    if (props.activeCounselors[index]) {
      if (props.activeCounselors[index].lastName === "Alexander") {
        return counselorImages[`${props.activeCounselors[index].firstName} ${props.activeCounselors[index].lastName}`]
      } else {
        return counselorImages[`${props.activeCounselors[index].lastName}`]
      }
    }
  }

  const handleCounselorVideo = (index) => {
    const counselorVideos = {
      'Kinney': 'https://www.youtube.com/embed/nla-SwIr6RQ',
      'Ordonez': 'https://www.youtube.com/embed/MgVJNl8v_BI',
      'Nussbaum' :'https://www.youtube.com/embed/nlT6aOYI--c',
      'Hannah': 'https://www.youtube.com/embed/FY14vaF2s4M',
      'Gregory': 'https://www.youtube.com/embed/ROmzb_wGnj0',
      'Douglas': 'https://www.youtube.com/embed/egvcCdKqljk',
      'Saavedra': 'https://www.youtube.com/embed/W_Tlv9O_71Q?si=GpnDtDDFqz9uUOpq',
      'Crump': 'https://www.youtube.com/embed/cdNHnGegGfs?si=ZHxwjcMMhuw08xGa',
      'Yermenos': 'https://www.youtube.com/embed/T6N6r5BqP7g?si=F5udMy1eF-JYNXip',
      'Elayna Alexander': 'https://www.youtube.com/embed/nCigSY5CnrQ',
    // Below names are for testing only.
    // Youtube URLs are the first 5 Youtube URLs from the above counselors
      'Montague': 'https://www.youtube.com/embed/nla-SwIr6RQ',
      'Kendrick': 'https://www.youtube.com/embed/MgVJNl8v_BI',
      'Antrobus' : 'https://www.youtube.com/embed/nlT6aOYI--c',
      'Mancke': 'https://www.youtube.com/embed/O8xMScpt6x0',
    }
    if (props.activeCounselors[index]) {
      if (props.activeCounselors[index].lastName === "Alexander") {
        return counselorVideos[`${props.activeCounselors[index].firstName} ${props.activeCounselors[index].lastName}`]
      } else {
        return counselorVideos[`${props.activeCounselors[index].lastName}`]
      }
    }
  }

  const displayCoaches = (cnt) => {
    let index = offset
    return _.times(cnt, () => {
      let active = currentIndex === index
      let c = displayCoach(index, active)
      index = nextIndex(index)
      return c
    })
  }

  const displayCoach = (index, active) => {
    return (
      <div className={`coaches-image ${active ? 'active' : ''}`} key={index}>
        <a href={"#"} onClick={(e) => coachImageClick(e, index)}>
          <img className='coaches-image' src={handleCounselorImages(index)} alt={props.activeCounselors[index].firstName}/>
        </a>
      </div>
    )
  }

  const clickNext = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()

    setOffset(nextOffset)
  }

  const nextOffset = () => {
    return offset < props.activeCounselors.length - 1 ? offset + 1 : 0
  }

  const nextIndex = (index) => {
    return index < props.activeCounselors.length - 1 ? index + 1 : 0
  }

  const renderCoaches = () => {
    let coachCnt = props.activeCounselors.length
    let cnt
    let next
    if (width < 650) {
      cnt = coachCnt < 2 ? coachCnt : 2
      next = coachCnt > 2
    } else if (width < 1200) {
      cnt = coachCnt < 5 ? coachCnt : 5
      next = coachCnt > 5
    } else {
      cnt =  coachCnt
      next = false
    }

    return <div className='coaches-box-1'>
      { displayCoaches(cnt) }
      <div className={`${next ? 'show' : 'hide'}`}>
        <a href={"#"} onClick={(e) => clickNext(e)}>
          <div className='counselor-more'>
            <div className='rectangle'>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
              <div className='more'>More</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  }

  return (
    props.activeCounselors && <div className='coaches-section-wrapper'>
      {
        renderCoaches()
      }
      <div className='coaches-box-2'>
        <div className='coaches-bio-container'>
          <div className='coaches-images-lg'>
            <img className='coaches-image-lg' src={handleCounselorImages(currentIndex)}/>
          </div>
          <div className='coaches-bio-wrapper'>
            <div className='coach-landing-intro'>
              <FormattedMessage id='app.landing-page.coaches.hello-1'/>
              { props.activeCounselors[currentIndex].firstName }
              <FormattedMessage id='app.landing-page.coaches.hello-2'/>
            </div>
            <div className='coaches-bio'>
              <div className='landing-paragraph coach-landing-bio'>
                { bio(currentIndex) }
              </div>
            </div>
          </div>
        </div>
        <div className='coach-landing-video'>
          <iframe width="auto" height="486"
                  src={handleCounselorVideo(currentIndex)}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        </div>
      </div>
    </div>
  )
}
